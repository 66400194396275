import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import {
  CHART_EDIT_CONST,
  ECG_CHART_UNIT,
  TEN_SEC_STRIP_DETAIL,
} from 'constant/ChartEditConst';
import { HR_REVIEW_HISTOGRAM_TYPE } from 'constant/HrReviewConst';
import Const from 'constant/Const';

import {
  getCaliperActionCreatorDuckMap,
  selectCaliperDuckMap,
  selectTenSecStripDuckMap,
} from 'util/selectTenSecDuckMap';
import { handleOpenEventReviewInNewTab } from 'util/TenSecStripDetailUtil';
import { convertPresentText } from 'util/HrReviewUtil';

import useAuthority from 'component/hook/useAuthority';
import useShallowEqualSelector from 'component/hook/useShallowEqualSelector';
import useGetEventTypeOfWI from 'component/hook/useGetEventTypeOfWI';

import TenSecStripDetailHeader from 'component/fragment/test-result/share/tenSecStripDetail/TenSecStripDetailHeader';

import { selectHrSelectedValueState } from 'redux/duck/hrReviewDuck';
import {
  selectIsRawDataOnly,
  setBeatContextmenuRequest,
} from 'redux/duck/testResultDuck';
import { setIsTenSecStripEditMode } from 'redux/duck/shapeReviewDuck';

import useEditBeatEvent from './useEditBeatEvent';

const {
  ARROW_LEFT,
  ARROW_RIGHT,
  ESCAPE,
  KEY_Q,
  KEY_W,
  KEY_E,
  KEY_R,
  KEY_Z,
  KEY_T,
  KEY_C,
  KEY_V,
} = Const.KEY_MAP;

const actionConst = {
  addBeat: 'addBeat',
  editBeat: 'editBeat',
  deleteBeat: 'deleteBeat',
  caliperMode: 'caliperMode',
  tickMarkerOnOff: 'tickMarkerOnOff',
};

function TenSecStripDetailHeaderContainer(props) {
  const {
    chartOption,
    setChartOption,
    selectedBeatBtnInfoList,
    selectedBeatOption,
    initTenSecStripDetail,
  } = props;

  // local state
  const { editModeType, isCaliperMode, isTickMarksMode, tabType } = chartOption;
  const dispatch = useDispatch();
  const { isReadOnly } = useAuthority();

  // redux selectors
  const tenSecStripDetail = useShallowEqualSelector(
    (state) => selectTenSecStripDuckMap(state)[tabType]
  );
  const {
    ecgRaw,
    onsetWaveformIdx,
    terminationWaveformIdx,
    beatsOrigin,
    pending,
  } = tenSecStripDetail || {};

  const caliperPlotLines = useShallowEqualSelector(
    (state) => selectCaliperDuckMap(state)[tabType].caliperPlotLines
  );
  const { histType } = useShallowEqualSelector(selectHrSelectedValueState);
  const isOpenBeatContextmenu = useShallowEqualSelector(
    (state) => state.testResultReducer.eventReview.isOpenBeatContextmenu
  );
  const isRawDataOnly = useShallowEqualSelector(selectIsRawDataOnly);

  const isTenSecStripEditing = useShallowEqualSelector(
    (state) => state.shapeReviewReducer.tenSecStripEditMode.tenSecStripEditing
  );

  const typeOfCenterWI = useGetEventTypeOfWI({
    selectWaveformIndex:
      onsetWaveformIdx + ECG_CHART_UNIT.HALF_TEN_SEC_WAVEFORM_IDX,
    onsetWaveformIndex: onsetWaveformIdx,
    terminationWaveformIndex: terminationWaveformIdx,
    beats: beatsOrigin,
  });
  const isRRHist =
    tabType === TEN_SEC_STRIP_DETAIL.TAB.HR_REVIEW &&
    histType === HR_REVIEW_HISTOGRAM_TYPE.RR;
  const beatRRIValue = `${
    isRRHist && !pending
      ? convertPresentText(tenSecStripDetail.episodeBeatInfo[1], histType)
      : '-'
  } sec`;

  // redux dispatches
  const handleSetBeatContextmenu = (payload) => {
    !isReadOnly && dispatch(setBeatContextmenuRequest(payload));
  };
  const handleSetIsCaliperMode = (boolean) => {
    dispatch(
      getCaliperActionCreatorDuckMap('setIsCaliperMode', boolean)[tabType]()
    );
  };
  const handleIsTickMarksMode = (boolean) => {
    dispatch(
      getCaliperActionCreatorDuckMap('setIsTickMarksMode', boolean)[tabType]()
    );
  };

  const handleSetIsTenSecStripEditMode = (tenSecStripEditing) => {
    dispatch(setIsTenSecStripEditMode(tenSecStripEditing));
  };

  const { editBeat, deleteBeat } = useEditBeatEvent({
    tabType,
    initTenSecStripDetail,
  });

  const btnNormal = useRef();
  const btnAPC = useRef();
  const btnVPC = useRef();
  const btnNoise = useRef();
  // [event Listener] - keydown - 4 shortcut(add beat & edit beat & caliper)
  //  * close 10s strip short cut: EcgChartListContainer에서 동작
  useEffect(() => {
    const onKeyDownEventListener = (e) => {
      if (e?.isTriggeredMap?.tenSecStripKeyDown) {
        return;
      } else {
        e.isTriggeredMap = { tenSecStripKeyDown: true };
      }

      const { code, altKey, ctrlKey, metaKey, shiftKey } = e;

      //
      const isEditModeChangeBeat =
        editModeType === TEN_SEC_STRIP_DETAIL.EDIT_MODE.CHANGE_BEAT;
      const isModeAddBeat =
        editModeType === TEN_SEC_STRIP_DETAIL.EDIT_MODE.ADD_BEAT;
      const isPressedModifierKey = altKey || ctrlKey || metaKey || shiftKey;
      const availableSingleShortCut =
        !isPressedModifierKey && isEditModeChangeBeat;

      //
      const isAddBeatShortCut = !isPressedModifierKey && code === KEY_T;
      const isCaliperModeShortCut = !isModeAddBeat && code === KEY_C;
      const isTickMarksModeShortCut = !isModeAddBeat && code === KEY_V;
      const isDeleteBeatModeShortCut = isEditModeChangeBeat && code === KEY_Z;
      const isEditBeatModeShortCut =
        availableSingleShortCut &&
        [KEY_Z, KEY_Q, KEY_W, KEY_E, KEY_R].includes(code);

      //
      const isAvailableShortCut =
        isAddBeatShortCut ||
        isCaliperModeShortCut ||
        isTickMarksModeShortCut ||
        isDeleteBeatModeShortCut ||
        isEditBeatModeShortCut;

      let action;
      if (isAddBeatShortCut) {
        action = actionConst.addBeat;
      } else if (isDeleteBeatModeShortCut) {
        action = actionConst.deleteBeat;
      } else if (isEditBeatModeShortCut) {
        action = actionConst.editBeat;
      } else if (isCaliperModeShortCut) {
        action = actionConst.caliperMode;
      } else if (isTickMarksModeShortCut) {
        action = actionConst.tickMarkerOnOff;
      }

      // shape review에서 caliper mode, tick marks mode는 사용 가능
      const validateKeyDownEvent = !code || isReadOnly || !isAvailableShortCut;

      if (validateKeyDownEvent) return;

      // todo: jyoon[refactoring]
      const strategy = {
        [actionConst.caliperMode]: () => {
          handleSetIsCaliperMode(!isCaliperMode);
        },
        [actionConst.tickMarkerOnOff]: () => {
          onClickTickMarks();
        },
        [actionConst.addBeat]: () => {
          onClickAddBeat();
        },
        [actionConst.deleteBeat]: () => {
          onClickRemoveBeat();
        },
        [actionConst.editBeat]: () => {
          let beatTypeValue;

          switch (code) {
            case KEY_Q: // q, 0
              btnNormal.current.click();
              break;
            case KEY_W: // w, 1
              btnAPC.current.click();
              break;
            case KEY_E: // e, 2
              btnVPC.current.click();
              break;
            case KEY_R: // r, 3
              btnNoise.current.click();
              break;
            default:
              return;
          }

          beatTypeValue !== undefined && onClickBeatOptions(beatTypeValue);
        },
      };

      strategy[action]();
    };

    document.addEventListener('keydown', onKeyDownEventListener);
    return () => {
      document.removeEventListener('keydown', onKeyDownEventListener);
    };
  }, [
    tabType,
    isReadOnly,
    editModeType,
    tenSecStripDetail,
    isOpenBeatContextmenu,
    selectedBeatBtnInfoList,
    //
    isCaliperMode,
    caliperPlotLines,
    //
    onClickBeatOptions,
    onClickRemoveBeat,
  ]);

  // functions
  function onClickRemoveBeat() {
    deleteBeat({ selectedBeatBtnInfoList });
  }
  function onClickBeatOptions(beatTypeValue) {
    editBeat({ beatType: beatTypeValue, selectedBeatBtnInfoList });
  }

  const onClickTickMarks = () => {
    const isDisabledByCaliper =
      (isCaliperMode && caliperPlotLines.length !== 2) ||
      editModeType === TEN_SEC_STRIP_DETAIL.EDIT_MODE.ADD_BEAT;
    if (isDisabledByCaliper) return;

    handleIsTickMarksMode(!isTickMarksMode);
  };
  const onClickCaliper = () => {
    handleSetIsCaliperMode(!isCaliperMode);
  };
  const onClickAddBeat = () => {
    const isShapeReview = TEN_SEC_STRIP_DETAIL.TAB.SHAPE_REVIEW === tabType;

    initTenSecStripDetail();
    handleSetBeatContextmenu(false);

    // shape review에서 비트를 추가하는 경우에 사이드패널의 편집을 방지하기 위한 편집 중 상태를 처리하기 위함
    if (isShapeReview) {
      handleSetIsTenSecStripEditMode(!isTenSecStripEditing);
    }

    const prevEditModeType = chartOption.editModeType;
    if (prevEditModeType === TEN_SEC_STRIP_DETAIL.EDIT_MODE.ADD_BEAT) {
      return;
    }

    setChartOption((prev) => {
      return {
        ...prev,
        editModeType: TEN_SEC_STRIP_DETAIL.EDIT_MODE.ADD_BEAT,
      };
    });
  };

  const onClickEsc = () => {
    const escEvent = new KeyboardEvent('keyup', {
      key: ESCAPE,
    });
    window.dispatchEvent(escEvent);
  };
  const onClickMove = (e, direction) => {
    console.log(`🌸 > onClickMove > e:`, e);
    switch (direction) {
      case CHART_EDIT_CONST.TEN_SEC_STRIP_MOVE_DIR.PREV:
        const keyupArrowLeft = new KeyboardEvent('keydown', {
          key: ARROW_LEFT,
        });
        window.dispatchEvent(keyupArrowLeft);
        break;
      case CHART_EDIT_CONST.TEN_SEC_STRIP_MOVE_DIR.NEXT:
        const keyupArrowRight = new KeyboardEvent('keydown', {
          key: ARROW_RIGHT,
        });
        window.dispatchEvent(keyupArrowRight);
        break;
      default:
        break;
    }
  };
  const onClickAmplitude = (amplitudeVoltage) => {
    setChartOption((prev) => {
      return { ...prev, chart: { ...prev.chart, amplitude: amplitudeVoltage } };
    });
  };
  const onClickOpenInNew = () => {
    handleOpenEventReviewInNewTab({
      tabType,
      dataLength: ecgRaw.length,
      typeOfCenterWI,
      onsetWaveformIdx,
    });
  };

  return (
    <TenSecStripDetailHeader
      // props
      chartOption={chartOption}
      // local state
      isRRHist={isRRHist}
      beatRRIValue={beatRRIValue}
      isReadOnly={isReadOnly}
      selectedBeatOption={selectedBeatOption}
      onClickTickMarks={onClickTickMarks}
      onClickCaliper={onClickCaliper}
      onClickAddBeat={onClickAddBeat}
      onClickRemoveBeat={onClickRemoveBeat}
      onClickBeatOptions={onClickBeatOptions}
      onClickEsc={onClickEsc}
      onClickMove={onClickMove}
      onClickAmplitude={onClickAmplitude}
      onClickOpenInNew={onClickOpenInNew}
      // redux state
      isRawDataOnly={isRawDataOnly}
      tenSecStripDetail={tenSecStripDetail}
      caliperPlotLines={caliperPlotLines}
      //
      editBtnRef={{ btnNormal, btnAPC, btnVPC, btnNoise }}
    />
  );
}

export default TenSecStripDetailHeaderContainer;
