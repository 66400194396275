import { useDispatch, batch } from 'react-redux';
import { useLocation } from 'react-router';
import { useIntl } from 'react-intl';
import { useTheme } from 'styled-components';

import { TAB_VALUE_OBJECT } from 'constant/Const';
import {
  EVENT_CONST_TYPES,
  EVENT_GROUP_TYPE,
  REPORT_SECTION,
} from 'constant/EventConst';
import {
  BASIS_AMPLITUDE_RATE,
  REPORT_EVENT_EDITOR_STEP_MAP,
} from 'constant/ReportConst';
import { showDialog } from 'redux/duck/dialogDuck';
import {
  deleteReportEventRequested,
  setReportEventEditorStart,
  getNextReportEvent,
  updatePTEReportInfoRequested,
  selectSideTabValue,
} from 'redux/duck/testResultDuck';
import { deleteReportEventRequested as deleteBeatReviewReportEventRequested } from 'redux/duck/beatReviewDuck';
import { deleteReportEventRequested as deleteHrReviewReportEventRequested } from 'redux/duck/hrReviewDuck';
import useGetRepresentativeMarkersFunc from './useGetRepresentativeMarkersFunc';
import { ECG_CHART_UNIT } from 'constant/ChartEditConst';
import useShallowEqualSelector from './useShallowEqualSelector';

function useGetReportEventEditorFunc() {
  const intl = useIntl();
  const theme = useTheme();
  const dispatch = useDispatch();
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  const tabValue = urlSearchParams.get('tab');

  const { resetRepresentativeStates } = useGetRepresentativeMarkersFunc();

  const sideTabValue = useShallowEqualSelector(selectSideTabValue);
  const isReportGroupSelected = sideTabValue === EVENT_GROUP_TYPE.REPORT;

  /**
   * 리포트 담기 시나리오를 시작하면서 기존 대표 Strip 과 Selection 정보와 Marker 를 초기화 실행
   * @param {string} eventType
   * @param {ReportEventEditorInitState} initialState
   */
  const startReportEventEditor = (eventType, initialState) => {
    resetRepresentativeStates();

    const {
      //
      reportEventId,
      reportSection,
      annotation,
      mainRepresentativeInfo,
      subRepresentativeInfo,
    } = initialState;
    /** @type {import('constant/ReportConst').ReportEventEditorState} ReportEventEditor 모듈의 Global State 초기화 값 */
    let initSubState = {};
    if (reportEventId) {
      initSubState.reportEventId = reportEventId;
    }
    if (reportSection) {
      initSubState.prevSelectedReportSection = reportSection;
      initSubState.selectedReportSection = reportSection;
    }
    if (annotation) {
      initSubState.prevAnnotation = annotation;
    }
    if (mainRepresentativeInfo) {
      initSubState.prevMainRepresentativeInfo = {
        isRemoved: false,
        selectedMs: mainRepresentativeInfo.stripMs,
        representativeOnsetIndex:
          mainRepresentativeInfo.representativeOnsetIndex,
        representativeTerminationIndex:
          mainRepresentativeInfo.representativeTerminationIndex,
        amplitudeRate: mainRepresentativeInfo.amplitudeRate,
      };
      initSubState.mainRepresentativeInfo = {
        selectedMs: mainRepresentativeInfo.stripMs,
        representativeOnsetIndex:
          mainRepresentativeInfo.representativeOnsetIndex,
        representativeTerminationIndex:
          mainRepresentativeInfo.representativeTerminationIndex,
        amplitudeRate: mainRepresentativeInfo.amplitudeRate,
      };
      initSubState.selectedAmplitudeRate = mainRepresentativeInfo.amplitudeRate;
    }
    if (subRepresentativeInfo) {
      initSubState.prevSubRepresentativeInfo = {
        isRemoved: subRepresentativeInfo.isRemoved,
        selectedMs: subRepresentativeInfo.stripMs,
        representativeOnsetIndex:
          subRepresentativeInfo.representativeOnsetIndex,
        representativeTerminationIndex:
          subRepresentativeInfo.representativeTerminationIndex,
        amplitudeRate: subRepresentativeInfo.amplitudeRate,
        reportEventId: subRepresentativeInfo.reportEventId ?? null,
      };
      initSubState.subRepresentativeInfo = {
        isRemoved: subRepresentativeInfo.isRemoved,
        isMainChanged: false,
        selectedMs: subRepresentativeInfo.stripMs,
        representativeOnsetIndex:
          subRepresentativeInfo.representativeOnsetIndex,
        representativeTerminationIndex:
          subRepresentativeInfo.representativeTerminationIndex,
        amplitudeRate: subRepresentativeInfo.amplitudeRate,
      };
    }
    if (eventType === EVENT_CONST_TYPES.PATIENT) {
      initSubState.editorStep = REPORT_EVENT_EDITOR_STEP_MAP.TITLE;
    }

    dispatch(setReportEventEditorStart(eventType, initSubState));

    const newEvent = new KeyboardEvent('keydown', {
      key: 'Escape',
    });
    window.dispatchEvent(newEvent);
  };

  /**
   *
   * @param {number} reportEventId
   * @param {string} reportSection
   */
  const showDeleteReportEventConfirmDialog = (reportEventId, reportSection) => {
    if (reportSection === REPORT_SECTION.PTE) {
      dispatch(updatePTEReportInfoRequested(true));
      return;
    }

    dispatch(
      showDialog('ConfirmDialog', {
        message: intl.formatMessage({
          id: '99-TestResultPage-ConfirmDialog-title-01',
          description: '담은 이벤트를 리포트에서 삭제하시겠습니까?',
          defaultMessage: '담은 이벤트를 리포트에서 삭제하시겠습니까?',
        }),
        confirmButtonText: intl.formatMessage({
          id: '99-TestResultPage-ConfirmDialog-confirmButtonText-01',
          description: '삭제',
          defaultMessage: '삭제',
        }),
        confirmButtonColor: theme.color.RED,
        onSubmit: () => {
          switch (tabValue) {
            case TAB_VALUE_OBJECT.HR_REVIEW: {
              return deleteHrReviewREportEvent(reportEventId);
            }
            case TAB_VALUE_OBJECT.EVENT_REVIEW: {
              return deleteEventReviewReportEvent(reportEventId, reportSection);
            }
            case TAB_VALUE_OBJECT.BEAT_REVIEW: {
              return deleteBeatReviewReportEvent(reportEventId);
            }
            default: {
              return;
            }
          }
        },
      })
    );
  };

  /**
   *
   * @param {number} reportEventId
   * @param {string} reportSection
   */
  const deleteEventReviewReportEvent = (reportEventId, reportSection) => {
    batch(() => {
      resetRepresentativeStates();
      dispatch(
        deleteReportEventRequested(
          reportEventId,
          isReportGroupSelected && getNextReportEvent(reportSection)
        )
      );
    });
  };

  /**
   *
   * @param {number} reportEventId
   */
  const deleteBeatReviewReportEvent = (reportEventId) => {
    dispatch(deleteBeatReviewReportEventRequested(reportEventId));
  };
  const deleteHrReviewREportEvent = (reportEventId) => {
    dispatch(deleteHrReviewReportEventRequested(reportEventId));
  };

  return {
    startReportEventEditor: startReportEventEditor,
    deleteReportEvent: showDeleteReportEventConfirmDialog,
  };
}

/**
 *
 * @param {number} selectedMs 10초 Strip 중심 시점 Timestamp(MS)
 * @param {number} recordingStartMs 검사의 시작시간 Timestamp(MS)
 * @returns {SubRepresentativeStripInfo}
 */
export const getTenSecSubRepresentativeStripInfo = (
  selectedMs,
  recordingStartMs
) => {
  const result = {
    representativeOnsetIndex:
      (selectedMs - ECG_CHART_UNIT.FIVE_SEC - recordingStartMs) / 4,
    representativeTerminationIndex:
      (selectedMs + ECG_CHART_UNIT.FIVE_SEC - recordingStartMs) / 4,
    amplitudeRate: BASIS_AMPLITUDE_RATE,
    isRemoved: true,
    //
    reportEventId: null,
  };

  return result;
};

/**
 * @typedef ReportEventEditorInitState 리포트 이벤트 타입
 * @prop {string} [reportEventId]
 * @prop {string} [reportSection]
 * @prop {string} [annotation]
 * @prop {object} [mainRepresentativeInfo] Report Event 의 대표 Strip 정보
 * @prop {number} mainRepresentativeInfo.stripMs
 * @prop {number} mainRepresentativeInfo.representativeOnsetIndex
 * @prop {number} mainRepresentativeInfo.representativeTerminationIndex
 * @prop {number} mainRepresentativeInfo.amplitudeRate
 * @prop {boolean} [mainRepresentativeInfo.isRemoved]
 * @prop {object} [subRepresentativeInfo] Report 중 PTE Section 의 (10초 길이)보조 Strip 정보
 * @prop {number} subRepresentativeInfo.stripMs
 * @prop {number} subRepresentativeInfo.representativeOnsetIndex
 * @prop {number} subRepresentativeInfo.representativeTerminationIndex
 * @prop {number} subRepresentativeInfo.amplitudeRate
 * @prop {boolean} subRepresentativeInfo.isRemoved
 * @prop {string} [subRepresentativeInfo.reportEventId]
 */
/**
 * @typedef SubRepresentativeStripInfo
 * @prop {number} representativeOnsetIndex
 * @prop {number} representativeTerminationIndex
 * @prop {number} amplitudeRate
 * @prop {boolean} isRemoved
 * @prop {string | null} reportEventId
 */

export default useGetReportEventEditorFunc;
