import { batch } from 'react-redux';

import {
  CHART_EDIT_CONST,
  EDIT_CHART,
  SELECTION_MARKER_TYPE,
  TEN_SEC_STRIP,
} from 'constant/ChartEditConst';
import {
  CLASS_HUINNO_EVENT_MARKER_PRIORITY_SELECTED,
  EVENT_CONST_TYPES,
} from 'constant/EventConst';
import { LONG_TERM_CHART } from 'constant/ChartConst';

import { hasOnsetMarker } from 'component/ui/chart/LongTermChart/LongTermHighcharts';

import { WaveformIndex } from '@type/ecgEventType/eventUnit';

import { events, publishType } from './PubSubUtil';
import { isRightClick } from './ChartUtil';

// drag event trigger timing: time to time
// need to check the drag previous drag event
// let previousDragEvent: DragEvent | null = null;
const CONST_CLASS_HUINNO_EVENT_MARKER_PRIORITY_SELECTED =
  CLASS_HUINNO_EVENT_MARKER_PRIORITY_SELECTED;

interface dragMouseEvent extends MouseEvent {
  isDragging: boolean;
  isSelectableChart: boolean;
  isSelectableRepresentativeStrip: boolean;
  //
  clickedWaveformIndex: WaveformIndex;
  clickedWaveformIndexPixels: number;
}
interface HighchartEvent extends DragEvent {
  chartX: WaveformIndex;
}
interface DragEventObj {
  previousDragEvent: { chartX: WaveformIndex } | null;
  currentDragEvent: HighchartEvent | null;
}

interface ChartInst {
  beatLabelButtonInstList: any;
}

let dragEventObj: DragEventObj = {
  previousDragEvent: null,
  currentDragEvent: null,
};
export function initDragEventObj(): void {
  dragEventObj = {
    previousDragEvent: null,
    currentDragEvent: null,
  };
}
export enum DragConfig {
  DRAG_THRESHOLD = 25,
}

/****************************************************************/
/* drag feature: button select, highlight                       */
/*  - used 10s strip                                            */
/****************************************************************/
export function dragFeatureOfTensecStrip({
  tensecStripChartInst,
  //
  event,
  mouseDownPointWfi,
  initTenSecStripDetail,
  //
  setSelectedBeatBtnInfoList,
  setChartOption,
  setSelectedBeatOption,
  //
  chartEditUtil,
  _renderSelectionHighlight,
}: {
  tensecStripChartInst: any;
  //
  event: HighchartEvent;
  mouseDownPointWfi: number;
  initTenSecStripDetail: any;
  //
  setSelectedBeatBtnInfoList: any;
  setChartOption: any;
  setSelectedBeatOption: any;
  //
  chartEditUtil: any;
  _renderSelectionHighlight: any;
}): void {
  try {
    const prevDragEventWfi = mouseDownPointWfi;
    const currDragEventWfi = Math.round(
      tensecStripChartInst.xAxis[0].toValue(event.chartX)
    );

    if (Number.isNaN(prevDragEventWfi) || Number.isNaN(currDragEventWfi)) {
      return;
    }

    chartEditUtil.removeSelectionHighlightAll();
    _renderSelectionHighlight(mouseDownPointWfi, currDragEventWfi);

    // step3: check beatLabelButton by drag event
    let { beatLabelButtonInstList } = tensecStripChartInst;

    for (let beatLabelButtonInst of beatLabelButtonInstList) {
      const { xAxisPoint, beatType } =
        beatLabelButtonInst.beatLabelButtonMetaData;
      const isBeatLabelButtonBetweenDragRange = isBetween({
        xAxisPoint,
        firstWfi: prevDragEventWfi,
        secondWfi: currDragEventWfi,
      });

      // update state; clicked beat label button
      // 0: unselected, 2: selected
      let buttonState = 0;
      if (isBeatLabelButtonBetweenDragRange) {
        buttonState = 2;
        setSelectedBeatOption(beatType);
      }
      beatLabelButtonInst.setState && beatLabelButtonInst.setState(buttonState);
    }
  } catch (error) {
    console.error(error);
  }
}

/**
 * xAxisPoint가 firstWfi, secondWfi사이 위치 여부 판단
 *
 * @param param0
 * @returns
 */
export function isBetween({
  xAxisPoint,
  firstWfi,
  secondWfi,
}: {
  xAxisPoint: WaveformIndex;
  firstWfi: WaveformIndex;
  secondWfi: WaveformIndex;
}): boolean {
  const min = Math.min(firstWfi, secondWfi);
  const max = Math.max(firstWfi, secondWfi);

  return xAxisPoint >= min && xAxisPoint < max;
}

// Moved click event logic from LongTermHighcharts.js to DragUtil.ts
export function onClick(
  event: any, // :HighchartEvent,
  {
    _chartInst,
    _chartEditInst,
    setTenSecStrip,
    setArrhythmiaContextmenu,
    setArrhythmiaContextmenuPosition,
    //
    isSelectableChart,
    isSelectableRepresentativeStrip,
  }: {
    _chartInst: any;
    _chartEditInst: any;
    //
    setTenSecStrip: any;
    setArrhythmiaContextmenu: any;
    setArrhythmiaContextmenuPosition: any;
    //
    isSelectableChart: any;
    isSelectableRepresentativeStrip: any;
  }
) {
  const validationLongtermChartClickEvent =
    validateLongtermChartClickEvent(event);
  if (!validationLongtermChartClickEvent) {
    return;
  }

  event.isSelectableChart = isSelectableChart;
  event.isSelectableRepresentativeStrip = isSelectableRepresentativeStrip;

  const { layerX, pageX, pageY } = event;
  const clickedWfi = Math.floor(_chartInst.xAxis[0].toValue(layerX));
  event.clickedWaveformIndex = clickedWfi; // unit: waveformIndex
  event.clickedWaveformIndexPixels = layerX; // unit: pixels

  const isClickAble = isSelectableChart || isSelectableRepresentativeStrip;
  const isStepOfSelectRepresentativeStrip =
    event.isSelectableRepresentativeStrip &&
    !event.target.classList.contains(
      CONST_CLASS_HUINNO_EVENT_MARKER_PRIORITY_SELECTED
    );

  if (!isClickAble || isStepOfSelectRepresentativeStrip) return;

  const { shiftKey, isDragging } = event;
  let preventTriggerRenderSelectionStrip = false;

  const {
    [SELECTION_MARKER_TYPE.ONSET]: {
      representativeTimestamp: onsetRepresentativeTimestamp,
    },
    [SELECTION_MARKER_TYPE.TERMINATION]: {
      representativeTimestamp: terminationRepresentativeTimestamp,
    },
  } = _chartEditInst.getSelectionStrip();

  const tenSecStripInst = window[CHART_EDIT_CONST.MAIN_TEN_SEC_STRIP];
  const validationSelectionMarkerResult = _validationSelectionMarker({
    event,
    _chartEditInst,
    tenSecStripInst,
  });
  const isCaseOfRemoveContextmenu =
    validationSelectionMarkerResult.filter(
      (v: any) => v.type === EDIT_CHART.VALIDATION.REMOVE_CONTEXTMENU
    ).length > 0
      ? true
      : false;

  validationSelectionMarkerResult.forEach((validation: any) => {
    switch (validation.type) {
      case EDIT_CHART.VALIDATION.EXTEND_SELECTION_STRIP:
        setTimeout(() => {
          setArrhythmiaContextmenuPosition({
            positionX: event.pageX,
            positionY: event.pageY,
          });
        });
        break;
      case EDIT_CHART.VALIDATION.REMOVE_CONTEXTMENU:
        preventTriggerRenderSelectionStrip = true;
        setArrhythmiaContextmenu(false);
        break;
      case EDIT_CHART.VALIDATION.SHOW_CONTEXTMENU:
        events.subscribe(setArrhythmiaContextmenu.bind(this, true));
        setArrhythmiaContextmenuPosition({
          positionX: event.pageX,
          positionY: event.pageY,
        });
        break;
      case EDIT_CHART.VALIDATION.REMOVE_SELECTION_HIGHLIGHT_ALL:
        !isCaseOfRemoveContextmenu &&
          setTimeout(() => {
            _chartEditInst.removeSelectionHighlightAll();
          });
        break;
      case EDIT_CHART.VALIDATION.RENDER_TENSEC_STRIP:
        setTimeout(() => {
          const {
            [SELECTION_MARKER_TYPE.ONSET]: {
              representativeTimestamp,
              representativeWaveformIndex,
            },
          } = _chartEditInst.getSelectionStrip();
          _chartEditInst.renderTenSecStrip(
            event,
            representativeTimestamp,
            representativeWaveformIndex
          );
        });
        break;
      case EDIT_CHART.VALIDATION.RENDER_SELECTION_STRIP:
        _chartEditInst.removeTenSecStrip();
        events.subscribe(
          setTenSecStrip.bind(this, {
            [TEN_SEC_STRIP.TYPE.RESET]: {
              type: TEN_SEC_STRIP.TYPE.RESET,
            },
          })
        );
        break;
      default:
        break;
    }
  });

  if (
    preventTriggerRenderSelectionStrip ||
    (shiftKey && event.isSelectableRepresentativeStrip) ||
    (shiftKey && !hasOnsetMarker())
  ) {
    return;
  }

  _chartEditInst.renderSelectionStrip(event, [
    onsetRepresentativeTimestamp,
    terminationRepresentativeTimestamp,
  ]);

  setTimeout(() => {
    events.publish(batch, publishType.CLICK);
  });
} // end of onClick of longTermHighcharts

function _validationSelectionMarker({
  event,
  _chartEditInst,
  tenSecStripInst,
}: {
  event: dragMouseEvent;
  _chartEditInst: any;
  tenSecStripInst: any;
}) {
  let validationResultList: { type: string; result: boolean }[] = [];

  const { isSelectableChart, shiftKey, isDragging } = event;
  const isTerminationEvent = shiftKey || isDragging;
  const hasOnsetSelectionMarker = _chartEditInst.hasOnsetSelectionMarker();
  const hasTerminationSelectionMarker =
    _chartEditInst.hasTerminationSelectionMarker();

  const showContextMenuCase =
    isSelectableChart && hasOnsetSelectionMarker && isTerminationEvent;
  const removeSelectionHighlightAll =
    (isTerminationEvent &&
      hasOnsetSelectionMarker &&
      hasTerminationSelectionMarker) || // 클릭으로 onset selection marker을 rendering한 경우
    (!isTerminationEvent &&
      hasOnsetSelectionMarker &&
      hasTerminationSelectionMarker); // selection strip을 수정하는 경우

  if (showContextMenuCase) {
    validationResultList.push({
      type: EDIT_CHART.VALIDATION.SHOW_CONTEXTMENU,
      result: false,
    });
  }
  if (removeSelectionHighlightAll) {
    validationResultList.push({
      type: EDIT_CHART.VALIDATION.REMOVE_SELECTION_HIGHLIGHT_ALL,
      result: false,
    });
  }

  // tenSecStrip detail이 open 되 경우
  if (tenSecStripInst && isTerminationEvent) {
    validationResultList.push({
      type: EDIT_CHART.VALIDATION.RENDER_SELECTION_STRIP,
      result: false,
    });
  }
  if (tenSecStripInst && !isTerminationEvent) {
    validationResultList.push({
      type: EDIT_CHART.VALIDATION.RENDER_TENSEC_STRIP,
      result: false,
    });
  }

  return validationResultList;
}

export function removeTenSecStrip({
  _chartEditInst,
  setTenSecStrip,
}: {
  _chartEditInst: any;
  setTenSecStrip: any;
}) {
  // init dom
  _chartEditInst.removeTenSecStrip();
  // init redux state
  events.subscribe(
    setTenSecStrip.bind(this, {
      [TEN_SEC_STRIP.TYPE.RESET]: {
        type: TEN_SEC_STRIP.TYPE.RESET,
      },
    })
  );
}

export function validateLongtermChartClickEvent(event: any) {
  // Condition1: 우클릭 방지
  const isRightClickRst = isRightClick(event);
  // Condition2: lead off event 클릭 시 방지
  const isClickedLeadOffEvent = [...event.target.classList].some(
    (v) => v.indexOf(EVENT_CONST_TYPES.LEAD_OFF) > 0
  );
  // Condition3: chart와 chart 차이 공간 클릭
  const isClickedBtwChart =
    event.layerY < LONG_TERM_CHART.chartTopYaxisPixel ||
    event.layerY > LONG_TERM_CHART.chartBottomYaxisPixel;

  // 아래 조건 all false인 경우에만 click event 발생
  // true: validation pass, false: validation fail
  return (
    !isRightClickRst && // 우클릭 방지
    !isClickedLeadOffEvent &&
    !isClickedBtwChart
  );
}

export function validateMouseMoveTarget(event): boolean {
  // 10s strip event btn click을 의도 했으나 drag했을때 btn이 선택 안되는 현상을 방지하기 위해서
  // 10s strip에서 drag 가능한 범위를 event.target tagName, classList로 제한
  const availableTarget = {
    g: ['highcharts-series-group', 'highcharts-series', 'huinno-mouse-tracker'],
    rect: [
      'highcharts-plot-border',
      'highcharts-plot-background',
      'huinno-context-menu-area',
    ],
    path: [
      'highcharts-graph',
      'highcharts-plot-band',
      'highcharts-tracker-line',
      'highcharts-plot-border',
      'huinno-vertical-dotted-line',
      'huinno-context-menu-area',
      'huinno-event-marker-area',
      'huinno-ten-sec-strip-highlight-area',
    ],
  };
  const tagNameMapping = availableTarget[event.target.tagName];
  const isAvailableTarget = tagNameMapping !== undefined;
  const isAvailableClassList = tagNameMapping?.some((v) =>
    event.target.classList.value.includes(v)
  );

  if (isAvailableTarget === true && isAvailableClassList === true) {
    return true;
  }

  return false;
}

export function getIsAvailableMouseUp({
  _chartInst,
  mouseEvent,
  selectionStrip,
  chartOnsetWaveformIndex,
}) {
  let isAvailableMouseUp = false;

  //condition1: validation click event
  const validationLongtermChartClickEvent =
    validateLongtermChartClickEvent(mouseEvent);

  if (!validationLongtermChartClickEvent) return false;

  // condition2: 클릭시 마우스를 움직인 거리가 25 waveformIndex 이상인 경우에만 drag기능 활성화
  //           : (이하인 경우 클릭이 아닌 drag 아님)
  const isDraggableCondition = getIsDraggableCondition({
    _chartInst,
    mouseEvent,
    selectionStrip,
  });

  // condition3: 같은 차트를 드래그 여부 판단
  const manager = dragInfoManager();
  const dragRepresentativeOnsetWaveformIndex = manager.getDragInfo(
    manager.type.dragRepresentativeOnsetWaveformIndex
  );
  const isSameChartDrag =
    dragRepresentativeOnsetWaveformIndex === chartOnsetWaveformIndex;

  // condition4: virtuoso chart scrolling시 mouseUp 이벤트 발생 방지
  const isScrollingVirtuoso = manager.getDragInfo(manager.type.scrolling);

  // condition5: event-review에서 10초 strip reposition시 차트가 scroll될 때  mouseUp 이벤트 발생 방지
  //           : reposition이 일어나는 원인 selection strip 주위로 생긴 10s strip이 하단의 10s strip과 겹치는 경우
  const isReposition10SecStrip = manager.getDragInfo(
    manager.type.reposition10SecStrip
  );

  isAvailableMouseUp =
    (!isSameChartDrag && !isScrollingVirtuoso && !isReposition10SecStrip) || // 다른 차트에서 drag하면 무조건 mouseUp 이벤트 발생
    (isSameChartDrag && !isScrollingVirtuoso && isDraggableCondition); // 같은 차트에서 drag하면 클릭 가능한 경우에만 mouseUp 이벤트 발생

  return isAvailableMouseUp;
}

export function getIsDraggableCondition({
  _chartInst,
  mouseEvent,
  selectionStrip,
}) {
  // const dragThreshold = 1000; // 25 wfi
  const dragThreshold = DragConfig.DRAG_THRESHOLD; // 25 wfi
  const {
    onset: { clickedWaveformIndex: onsetClickedWaveformIndex },
  } = selectionStrip;
  const clickedLayerX = mouseEvent.layerX;

  const mouseUpClickedPosition = Math.round(
    _chartInst.xAxis[0].toValue(clickedLayerX)
  );
  const isDraggableCondition =
    Math.abs(onsetClickedWaveformIndex - mouseUpClickedPosition) >
    dragThreshold;
  return isDraggableCondition;
}

export function dragInfoManager() {
  if (window.mouseDownInfo === undefined) {
    window.mouseDownInfo = {};
  }
  return {
    type: {
      mouseDown: 'mouseDown',
      scrolling: 'scrolling',
      reposition10SecStrip: 'reposition10SecStrip',
      dragRepresentativeOnsetWaveformIndex:
        'dragRepresentativeOnsetWaveformIndex',
    },
    setDragInfo: ({ type, value }) => {
      window.mouseDownInfo[type] = value;
    },
    getDragInfo: (type) => {
      return window.mouseDownInfo[type];
    },
  };
}
