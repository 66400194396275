export const ComponentId = {
  TEN_SEC_STRIP_DETAIL: 'ten-sec-strip-detail',
  TEN_SEC_STRIP_DETAIL_CHART: 'ten-sec-strip-detail-chart',
  TEN_SEC_STRIP_CHART_CONTAINER: 'huinno-10sec-strip-detail-chart-container',
  TEN_SEC_BEAT_CHART_EVENT_BTNS: 'ten-sec-beat-chart-event-btns',
  //
  FOUR_SEC_STRIP_CHART_CONTAINER: 'huinno-4sec-strip-detail-chart-container',
  //
  BEAT_EDIT_CONTEXTMENU: 'huinno-beat-edit-contextmenu',
  //
  ORDERING_CONTEXTMENU: 'huinno-ordering-contextmenu',
};
