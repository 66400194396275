import React from 'react';
import ReactDOM from 'react-dom';

import {
  editType,
  shortCut,
  operandType,
  ChartBoxNumber,
  ComposeKey,
  UI_INTERACTION,
  CheckBoxAll,
  keyboardDirection,
  POSITION_MOVE_TYPE,
  validationKeyDownType,
  ShapeReviewRawAndEventCalledCase,
  ShapeReviewSectionArea,
} from 'constant/ShapeReviewConst';
import { ECTOPIC_TYPE } from 'constant/EventConst';
import {
  SORT_ORDER_TYPE_OPTION_LIST_MAP,
  SortOption,
} from 'constant/SortConst';

import { BeatEventSelectionInfo } from 'component/hook/useChartList';

import {
  CheckBoxStatus,
  ActivePanelType,
} from 'redux/container/fragment/test-result/shape-review/ShapeReviewFragmentContainer';

import { IShapeReviewSidePanelEventGroup } from 'component/fragment/test-result/shape-review/FormsPanel/FormsPanelListFragment';

import { onKeyDown } from '@type/reactTypes';
import { EctopicType } from '@type/ecgEventType/baseEventType';
import { WaveformIndex } from '@type/ecgEventType/eventUnit';
import {
  ChartListItem,
  Checkbox,
  LastSelectedSectionInfo,
  Matrix,
  OrderingTypeSettingType,
  SelectedItemInfo,
} from 'redux/duck/shapeReview/shapeReviewDuckType';

export function validationKeyDown({
  movedChartIndex,
  maxIndexOfPanel,
  chartList,
  isKeydownWithoutComposeKey,
  isKeydownWithCtrl,
  isKeydownWithShift,
  panelType,
  handleSetPage,
}: {
  movedChartIndex: number;
  maxIndexOfPanel: number;
  chartList: ChartListItem[];
  isKeydownWithoutComposeKey: boolean;
  isKeydownWithCtrl: boolean;
  isKeydownWithShift: boolean;
  panelType: ActivePanelType;
  handleSetPage: React.Dispatch<
    React.SetStateAction<{
      panelType: ActivePanelType;
      setPageType: POSITION_MOVE_TYPE;
    }>
  >;
}): { type: validationKeyDownType; doReturn: boolean }[] {
  let validationResult: { type: validationKeyDownType; doReturn: boolean }[] =
    [];
  // shift + 방향키 이동시 panel size 미만, 초과시
  if (movedChartIndex < 0 && isKeydownWithShift) {
    validationResult.push({
      type: validationKeyDownType.UNDER_PANEL_SIZE_WITH_SHIFT_KEY,
      doReturn: true,
    });
  }
  if (
    (movedChartIndex > maxIndexOfPanel ||
      movedChartIndex > chartList.length - 1) &&
    isKeydownWithShift
  ) {
    validationResult.push({
      type: validationKeyDownType.OVER_PANEL_SIZE_WITH_SHIFT_KEY,
      doReturn: false,
    });
  }
  if (
    (movedChartIndex > maxIndexOfPanel ||
      movedChartIndex > chartList.length - 1) &&
    isKeydownWithCtrl
  ) {
    validationResult.push({
      type: validationKeyDownType.OVER_PANEL_SIZE_WITH_CTRL_KEY,
      doReturn: false,
    });
  }
  // 페이지 이동
  if (movedChartIndex < 0 && isKeydownWithoutComposeKey) {
    validationResult.push({
      type: validationKeyDownType.MOVE_PREV_PAGE,
      doReturn: false,
    });
  }
  if (
    (movedChartIndex > maxIndexOfPanel ||
      movedChartIndex > chartList.length - 1) &&
    isKeydownWithoutComposeKey
  ) {
    validationResult.push({
      type: validationKeyDownType.MOVE_NEXT_PAGE,
      doReturn: true,
    });
  }

  //
  if (movedChartIndex < 0 || movedChartIndex > maxIndexOfPanel) {
    validationResult.push({
      type: validationKeyDownType.OVER_PANEL_SIZE,
      doReturn: true,
    });
  }

  if (movedChartIndex > chartList.length - 1) {
    validationResult.push({
      type: validationKeyDownType.OVER_CHART_LIST_LENGTH,
      doReturn: false,
    });
  }

  return validationResult;
}
export function makeSetSelectedItemList({
  chartList,
  ectopicType,
  chartItemInfo,
  selectedItemList = new Map(),
  lastSelectedSectionInfo,
  movedSelectedItem,
  panelType,
  lastChangedCheckboxStatus,
  setSelectedItemList,
}: {
  chartList: any;
  ectopicType: EctopicType;
  chartItemInfo: BeatEventSelectionInfo;
  selectedItemList: Map<number, SelectedItemInfo>;
  lastSelectedSectionInfo: LastSelectedSectionInfo;
  movedSelectedItem: SelectedItemInfo;
  panelType: any;
  lastChangedCheckboxStatus?: any;
  setSelectedItemList: React.Dispatch<
    React.SetStateAction<Map<number, SelectedItemInfo>>
  >;
}) {
  const isSelectedPanelTypeIsEvents =
    panelType === ShapeReviewSectionArea.EVENTS;

  if (!lastSelectedSectionInfo[0]) return;

  let selectedSectionInfo = getSelectionInfo(
    lastSelectedSectionInfo[0], // 선택 구간의 begin
    movedSelectedItem // shift + click으로 선택한 지점
  );
  let newState = new Map<number, SelectedItemInfo>();

  // step1: 선택 구간의 begin과 shift + click으로 선택한 지점 설정
  for (let i = selectedSectionInfo.start; i <= selectedSectionInfo.end; i++) {
    const matrixRow = i / chartItemInfo.columnNumber;
    const matrixColumn = i % chartItemInfo.columnNumber;
    const matrix: Matrix = [Number.parseInt(String(matrixRow)), matrixColumn];
    let checkbox: Checkbox = undefined;

    const originStatus = chartList[i]?.beats.waveformIndex.includes(
      chartList[i].originWaveformIndex
    );
    const terminationStatus = chartList[i]?.beats.waveformIndex.includes(
      chartList[i].terminationWaveformIndex
    );
    // lastSelectedSectionInfo의 체크박스나, selectedItemList의 처음 담긴 이벤트의 체크박스를 기준으로 하면 원하는 체크박스 옵션을 선택할 수가 없음
    // case 1. 마지막 위치의 변경된 이벤트의 체크박스를 기준으로 상태를 적용하면
    // ex. 두개를 모두 체크하고 shift를 이동하다 한개만 체크를 하고 shift 이동을 하게 되면 반복문을 돌면서 체크되었던 이벤트의 체크박스를 하나로 변경함
    // case 2. 처음 시작한 이벤트의 체크박스를 기준으로 상태를 적용하면
    // ex. 두개를 체크하고 가다가 하나로 변경하고 shift 이동을 하면 하나를 체크하는게 아니라 계속 두개를 체크한다.
    if (isSelectedPanelTypeIsEvents && lastChangedCheckboxStatus) {
      if (
        //이벤트 비트 모두 삭제된 케이스
        (!originStatus && !terminationStatus) ||
        //체크 모두 풀고나서 shift로 이동하는 케이스
        (!lastChangedCheckboxStatus[0] && !lastChangedCheckboxStatus[1]) ||
        // 체크박스 하나만 체크 했을때 실제 반복문을 돌면서 이벤트의 체크박스 상태와 다른 경우
        //ex. shift를 누르고 이동하기 시작한 이벤트의 체크박스는 onset 하나만 체크 하고 이동하는데
        // 반복문을 돌면서 termination 비트만 있는 케이스
        (lastChangedCheckboxStatus[0] !== originStatus &&
          lastChangedCheckboxStatus[1] !== terminationStatus)
      ) {
        continue;
      }
    }
    if (lastChangedCheckboxStatus?.some((v) => !v)) {
      if (lastChangedCheckboxStatus) {
        checkbox = lastChangedCheckboxStatus;
      }
    } else {
      checkbox = getCheckBoxByEctopicType(ectopicType, chartList[i], panelType);
    }

    newState.set(i, {
      index: i,
      matrix,
      checkbox,
    });
  }

  setSelectedItemList((prev) => {
    // step2: 이미 click, shift+click이 모두 선택 된 경우 해당 section 제거
    let prevState = new Map(prev);
    if (!!lastSelectedSectionInfo[0] && !!lastSelectedSectionInfo[1]) {
      let selectedSectionInfo = getSelectionInfo(
        lastSelectedSectionInfo[0],
        lastSelectedSectionInfo[1]
      );

      for (
        let i = selectedSectionInfo.start;
        i <= selectedSectionInfo.end;
        i++
      ) {
        prevState.delete(i);
      }
    }

    return new Map([...prevState, ...newState]);
  });
}

/**
 * click, shift+click한 지점을 index ascending order로 정렬해 반환합니다.
 * @param lastClickInfo
 * @param clickedData
 * @returns
 */
export function getSelectionInfo(
  lastClickInfo: SelectedItemInfo,
  clickedData: SelectedItemInfo
) {
  if (lastClickInfo.index < clickedData.index) {
    return {
      start: lastClickInfo.index,
      end: clickedData.index,
    };
  } else {
    return {
      start: clickedData.index,
      end: lastClickInfo.index,
    };
  }
}

/**
 * ectopicType에 따른 checkbox init 값
 *
 * @param ectopicType
 * @returns
 */
export function getCheckBoxByEctopicType(
  ectopicType: EctopicType,
  clickedData: any,
  panelType?: any
): [boolean] | [boolean, boolean] | undefined {
  const isSelectedPanelTypeIsEvents =
    panelType === ShapeReviewSectionArea.EVENTS;
  const originStatus = clickedData.beats.waveformIndex.includes(
    clickedData.originWaveformIndex
  );
  const terminationStatus = clickedData.beats.waveformIndex.includes(
    clickedData.terminationWaveformIndex
  );

  if (ectopicType === ECTOPIC_TYPE.ISOLATE) {
    return isSelectedPanelTypeIsEvents ? [originStatus] : [true];
  } else if (ectopicType === ECTOPIC_TYPE.COUPLET) {
    return isSelectedPanelTypeIsEvents
      ? [originStatus, terminationStatus]
      : [true, true];
  }
}

/**
 * keyDown validation list
 */
export function returnPressedComposeKey(e: React.KeyboardEvent) {
  // macOs -> control: control, option: alt, command: meta
  // window -> control: control, alt: alt,
  const { metaKey, ctrlKey, altKey, shiftKey } = e;

  const isKeydownWithoutComposeKey =
    !shiftKey && !metaKey && !ctrlKey && !altKey;
  let composeKey: ComposeKey = ComposeKey.none;

  if (isKeydownWithoutComposeKey) {
    composeKey = ComposeKey.none;
  } else if (ctrlKey || metaKey) {
    composeKey = ComposeKey.ctrlKey;
  } else if (altKey) {
    composeKey = ComposeKey.altKey;
  } else if (shiftKey) {
    composeKey = ComposeKey.shiftKey;
  }
  return composeKey;
}

export function isKeydownWithoutComposeKey(composeKey: string): boolean {
  return composeKey === ComposeKey.none;
}
export function isKeydownWithCtrl(composeKey: string): boolean {
  return composeKey === ComposeKey.ctrlKey;
}
export function isKeydownWithAlt(composeKey: string): boolean {
  return composeKey === ComposeKey.altKey;
}
export function isKeydownWithShift(composeKey: string): boolean {
  return composeKey === ComposeKey.shiftKey;
}

export function getRRIRatio({
  comparisonArray,
  referencePoint,
}: {
  comparisonArray: (number | null)[];
  referencePoint: number | null;
}): (number | string)[] {
  const result: (number | string)[] = comparisonArray.map((v) => {
    if (referencePoint === null || v === null) return 'N/A';

    const value: number = Number((referencePoint / v).toFixed(2));
    return isFinite(value) && value > 0 ? value : 'N/A';
  });

  return result;
}

export const uiInteractionMap = {
  [UI_INTERACTION.CLICK]({
    clickedData,
    ectopicType,
    panelType,
    setSelectedItemList,
    setLastSelectedSectionInfo,
  }: {
    clickedData: any;
    ectopicType: EctopicType;
    panelType: any;
    setSelectedItemList: React.Dispatch<
      React.SetStateAction<Map<number, SelectedItemInfo>>
    >;
    setLastSelectedSectionInfo: React.Dispatch<
      React.SetStateAction<LastSelectedSectionInfo>
    >;
  }) {
    ReactDOM.unstable_batchedUpdates(() => {
      clickedData.checkbox = getCheckBoxByEctopicType(
        ectopicType,
        clickedData,
        panelType
      ) as Checkbox;

      const isEventsPanelClickedCheckboxDeleted =
        !clickedData.checkbox[0] &&
        !clickedData.checkbox[1] &&
        panelType === ShapeReviewSectionArea.EVENTS;
      const selectItemInfo: SelectedItemInfo = {
        index: clickedData.index,
        matrix: clickedData.matrix,
        checkbox: clickedData.checkbox,
      };
      const clickCaseSelectedItemList = new Map([
        [clickedData.index, selectItemInfo],
      ]);

      setLastSelectedSectionInfo([selectItemInfo, undefined]);
      if (isEventsPanelClickedCheckboxDeleted) {
        setSelectedItemList(new Map());
        return;
      }

      setSelectedItemList(clickCaseSelectedItemList);
    });
  },
  [UI_INTERACTION.SHIFT_CLICK]({
    chartList,
    clickedData,
    ectopicType,
    chartItemInfo,
    selectedItemList,
    lastSelectedSectionInfo,
    panelType,
    lastChangedCheckboxStatus,
    setSelectedItemList,
    setLastSelectedSectionInfo,
  }: {
    chartList: any;
    clickedData: SelectedItemInfo;
    ectopicType: EctopicType;
    chartItemInfo: BeatEventSelectionInfo;
    selectedItemList: Map<number, SelectedItemInfo>;
    lastSelectedSectionInfo: LastSelectedSectionInfo;
    panelType: any;
    lastChangedCheckboxStatus: any;
    setSelectedItemList: React.Dispatch<
      React.SetStateAction<Map<number, SelectedItemInfo>>
    >;
    setLastSelectedSectionInfo: React.Dispatch<
      React.SetStateAction<LastSelectedSectionInfo>
    >;
  }) {
    ReactDOM.unstable_batchedUpdates(() => {
      setLastSelectedSectionInfo((prev: LastSelectedSectionInfo) => {
        return [prev[0], selectItemInfo] as LastSelectedSectionInfo;
      });
      makeSetSelectedItemList({
        chartList,
        ectopicType,
        chartItemInfo,
        selectedItemList,
        lastSelectedSectionInfo,
        movedSelectedItem: clickedData,
        panelType,
        setSelectedItemList,
      });
      clickedData.checkbox = getCheckBoxByEctopicType(
        ectopicType,
        clickedData,
        panelType
      );
      const selectItemInfo = {
        index: clickedData.index,
        matrix: clickedData.matrix,
        checkbox: clickedData.checkbox,
      };
    });
  },
  [UI_INTERACTION.META_CLICK]({
    clickedData,
    ectopicType,
    panelType,
    setSelectedItemList,
    setLastSelectedSectionInfo,
  }: {
    clickedData: any;
    ectopicType: EctopicType;
    panelType: any;
    setSelectedItemList: React.Dispatch<
      React.SetStateAction<Map<number, SelectedItemInfo>>
    >;
    setLastSelectedSectionInfo: React.Dispatch<
      React.SetStateAction<LastSelectedSectionInfo>
    >;
  }) {
    ReactDOM.unstable_batchedUpdates(() => {
      clickedData.checkbox = getCheckBoxByEctopicType(
        ectopicType,
        clickedData,
        panelType
      );
      const selectItemInfo = {
        index: clickedData.index,
        matrix: clickedData.matrix,
        checkbox: clickedData.checkbox,
      };

      setLastSelectedSectionInfo([selectItemInfo, undefined]);

      setSelectedItemList((prev: Map<number, SelectedItemInfo>) => {
        const existItem = prev.has(clickedData.index);
        const isEventsPanelClickedCheckboxDeleted =
          !clickedData.checkbox[0] &&
          !clickedData.checkbox[1] &&
          panelType === ShapeReviewSectionArea.EVENTS;

        if (existItem || isEventsPanelClickedCheckboxDeleted) {
          const newState = new Map(prev);
          newState.delete(clickedData.index);
          return newState;
        } else {
          return new Map([...prev, [selectItemInfo.index, selectItemInfo]]);
        }
      });
    });
  },

  moveFeature: {
    moveInit({
      keyDownEvent,
      setSelectedItemList,
      setLastSelectedSectionInfo,
    }: {
      keyDownEvent: onKeyDown;
      setSelectedItemList: React.Dispatch<
        React.SetStateAction<Map<number, SelectedItemInfo>>
      >;
      setLastSelectedSectionInfo: React.Dispatch<
        React.SetStateAction<LastSelectedSectionInfo>
      >;
    }) {
      const isPressKeyOneTwoKey =
        keyDownEvent.key === ChartBoxNumber.KeyOne ||
        keyDownEvent.key === ChartBoxNumber.KeyTwo;
      const isPressedMetaKey = keyDownEvent.ctrlKey || keyDownEvent.metaKey;
      const init: SelectedItemInfo = {
        index: 0,
        matrix: [0, 0],
        checkbox: [true, true],
      };

      if (isPressKeyOneTwoKey) return;
      if (isPressedMetaKey) {
        return setLastSelectedSectionInfo([
          {
            index: 0,
            matrix: [0, 0],
            checkbox: [false, false],
          },
          undefined,
        ]);
      } else {
        return ReactDOM.unstable_batchedUpdates(() => {
          setLastSelectedSectionInfo([init, undefined]);
          setSelectedItemList(new Map([[0, init]]));
        });
      }
    },
    [UI_INTERACTION.ARROW_KEYS]() {},
    [UI_INTERACTION.SHIFT_ARROW_KEYS]() {},
    [UI_INTERACTION.META_ARROW_KEYS]() {},
    move({
      keyDownEvent,
      triggerType,
      //
      chartList,
      chartItemInfo,
      moveBasis,
      ectopicType,
      //
      selectedItemList,
      lastSelectedSectionInfo,
      lastChangedCheckboxStatus,
      setSelectedItemList,
      setLastSelectedSectionInfo,
      //
      panelType,
      handleSetPage,
      handleSelectedCheckboxStatus,
    }: {
      keyDownEvent: onKeyDown;
      chartItemInfo: BeatEventSelectionInfo;
      chartList: ChartListItem[];
      triggerType: React.MutableRefObject<ShapeReviewRawAndEventCalledCase>;
      moveBasis: SelectedItemInfo;
      ectopicType: EctopicType;
      selectedItemList: Map<number, SelectedItemInfo>;
      lastSelectedSectionInfo: LastSelectedSectionInfo;
      lastChangedCheckboxStatus?: any;
      setSelectedItemList: React.Dispatch<
        React.SetStateAction<Map<number, SelectedItemInfo>>
      >;
      setLastSelectedSectionInfo: React.Dispatch<
        React.SetStateAction<LastSelectedSectionInfo>
      >;
      panelType: ActivePanelType;
      handleSetPage: React.Dispatch<
        React.SetStateAction<{
          panelType: ActivePanelType;
          setPageType: POSITION_MOVE_TYPE;
        }>
      >;
      handleSelectedCheckboxStatus?: (checkboxStatus: Checkbox) => void;
    }) {
      const keyboardDirection: string = keyDownEvent.key;
      const isPanelTypeForms = panelType === ShapeReviewSectionArea.FORMS;
      const composeKey: ComposeKey = returnPressedComposeKey(keyDownEvent);

      const basisOperand: Record<keyboardDirection, operandType> = {
        ArrowUp: [-1, 0],
        ArrowDown: [1, 0],
        ArrowLeft: [0, -1],
        ArrowRight: [0, 1],
      };
      const moveOperand = basisOperand[keyboardDirection as keyboardDirection];
      const info = {
        baseRowNumber: moveBasis.matrix[0],
        baseColumnNumber: moveBasis.matrix[1],
        targetRowNumber: moveBasis.matrix[0] + moveOperand[0],
        targetColumnNumber: moveBasis.matrix[1] + moveOperand[1],
      };
      const movedChartIndex =
        info.targetRowNumber * chartItemInfo.columnNumber +
        info.targetColumnNumber;

      let movedSelectedItem: SelectedItemInfo = {
        index: movedChartIndex,
        matrix: [
          Math.floor(movedChartIndex / chartItemInfo.columnNumber),
          movedChartIndex % chartItemInfo.columnNumber,
        ],
        checkbox: undefined,
      };

      const maxIndexOfPanel =
        chartItemInfo.columnNumber * chartItemInfo.rowNumber - 1;

      const originStatus = chartList[
        movedChartIndex
      ]?.beats.waveformIndex.includes(
        chartList[movedChartIndex].originWaveformIndex
      );

      const terminationStatus = chartList[
        movedChartIndex
      ]?.beats.waveformIndex.includes(
        chartList[movedChartIndex].terminationWaveformIndex
      );

      const isSelectedOnlyArrow =
        originStatus || terminationStatus || isPanelTypeForms;

      // validation
      const resultValidation = validationKeyDown({
        movedChartIndex,
        maxIndexOfPanel,
        chartList,
        isKeydownWithoutComposeKey: isKeydownWithoutComposeKey(composeKey),
        isKeydownWithCtrl: isKeydownWithCtrl(composeKey),
        isKeydownWithShift: isKeydownWithShift(composeKey),
        panelType,
        handleSetPage,
      });

      if (resultValidation.length !== 0) {
        for (const validation of resultValidation) {
          switch (validation.type) {
            case validationKeyDownType.UNDER_PANEL_SIZE_WITH_SHIFT_KEY:
              break;
            case validationKeyDownType.OVER_PANEL_SIZE_WITH_CTRL_KEY:
            case validationKeyDownType.OVER_PANEL_SIZE_WITH_SHIFT_KEY:
              const chartListLength = chartList.length - 1;
              movedSelectedItem = {
                index: chartListLength,
                matrix: [
                  Math.floor(chartListLength / chartItemInfo.columnNumber),
                  chartListLength % chartItemInfo.columnNumber,
                ],
                checkbox: undefined,
              };
              break;
            case validationKeyDownType.MOVE_PREV_PAGE:
              handleSetPage({
                panelType,
                setPageType: POSITION_MOVE_TYPE.PREV,
              });
              break;
            case validationKeyDownType.MOVE_NEXT_PAGE:
              handleSetPage({
                panelType,
                setPageType: POSITION_MOVE_TYPE.NEXT,
              });
              break;
            case validationKeyDownType.OVER_PANEL_SIZE:
              break;
            case validationKeyDownType.OVER_CHART_LIST_LENGTH:
              break;
            default:
              break;
          }
        }

        if (resultValidation.some((validation) => validation.doReturn)) return;
      }

      triggerType.current = ShapeReviewRawAndEventCalledCase.KEY_EVENT;

      /**
       * # keydown 선택 방법 3가지 case
       * # case1. only arrow keys
       * # case2. shift + arrow keys
       * # case3. ctrl + arrow keys
       */
      // # case1. only arrow keys
      if (isKeydownWithoutComposeKey(composeKey)) {
        ReactDOM.unstable_batchedUpdates(() => {
          movedSelectedItem.checkbox = getCheckBoxByEctopicType(
            ectopicType,
            chartList[movedChartIndex],
            panelType
          );
          if (isSelectedOnlyArrow) {
            setSelectedItemList(
              new Map([[movedChartIndex, movedSelectedItem]])
            );
          } else {
            setSelectedItemList(new Map());
          }
          setLastSelectedSectionInfo([movedSelectedItem, undefined]);
        });
        if (typeof handleSelectedCheckboxStatus === 'function') {
          handleSelectedCheckboxStatus(movedSelectedItem.checkbox);
        }
      }

      // # case2. shift + arrow keys
      if (isKeydownWithShift(composeKey)) {
        ReactDOM.unstable_batchedUpdates(() => {
          makeSetSelectedItemList({
            chartList,
            ectopicType,
            chartItemInfo,
            selectedItemList,
            lastSelectedSectionInfo,
            movedSelectedItem,
            panelType,
            lastChangedCheckboxStatus,
            setSelectedItemList,
          });
          const isPanelTypeForms = panelType === ShapeReviewSectionArea.FORMS;

          if (isPanelTypeForms) {
            movedSelectedItem.checkbox = getCheckBoxByEctopicType(
              ectopicType,
              chartList[movedChartIndex],
              panelType
            );
          } else {
            if (ectopicType === ECTOPIC_TYPE.COUPLET)
              movedSelectedItem.checkbox = [originStatus, terminationStatus];
            else {
              movedSelectedItem.checkbox = [originStatus];
            }
          }
          const movedUnCheckedCheckbox = lastChangedCheckboxStatus.some(
            (check: boolean) => !check
          );
          const unCheckedCheckbox =
            movedUnCheckedCheckbox || selectedItemList.size <= 0;

          // shift 이동 시 체크박스 없이 이동하는 경우 ctrl + arrow 와 동일하게 동작하도록 추가
          setLastSelectedSectionInfo((prev: LastSelectedSectionInfo) => {
            if (unCheckedCheckbox) {
              return [movedSelectedItem, undefined] as LastSelectedSectionInfo;
            } else {
              return [prev[0], movedSelectedItem] as LastSelectedSectionInfo;
            }
          });
        });
      }

      // # case3. ctrl + arrow keys
      if (isKeydownWithCtrl(composeKey)) {
        if (ectopicType === ECTOPIC_TYPE.COUPLET)
          movedSelectedItem.checkbox = [originStatus, terminationStatus];
        else {
          movedSelectedItem.checkbox = [originStatus];
        }
        setLastSelectedSectionInfo([movedSelectedItem, undefined]);
      }
    },
  },
  checkbox: {
    selectCheckBox({
      keyDownEvent,
      ectopicType,
      moveBasis,
      setSelectedItemList,
      setLastSelectedSectionInfo,
      handleSelectedCheckboxStatus,
    }: {
      keyDownEvent: onKeyDown;
      ectopicType: EctopicType;
      moveBasis: SelectedItemInfo;
      setSelectedItemList: React.Dispatch<
        React.SetStateAction<Map<number, SelectedItemInfo>>
      >;
      setLastSelectedSectionInfo: React.Dispatch<
        React.SetStateAction<LastSelectedSectionInfo>
      >;
      // redux func
      handleSelectedCheckboxStatus?: (CheckBoxStatus: Checkbox) => void;
    }) {
      ReactDOM.unstable_batchedUpdates(() => {
        setLastSelectedSectionInfo([moveBasis, undefined]);
        new Promise<any>((resolve) => {
          setSelectedItemList((prev: Map<number, SelectedItemInfo>) => {
            let clone: SelectedItemInfo = {
              index: moveBasis.index,
              matrix: moveBasis.matrix,
              checkbox: moveBasis.checkbox,
            };
            const targetItem = prev.get(clone.index);

            if (!!targetItem) {
              if (keyDownEvent.key === ChartBoxNumber.KeyOne) {
                clone.checkbox = [
                  !targetItem?.checkbox?.[0],
                  !!targetItem?.checkbox?.[1],
                ];
              } else if (keyDownEvent.key === ChartBoxNumber.KeyTwo) {
                clone.checkbox = [
                  !!targetItem?.checkbox?.[0],
                  !targetItem?.checkbox?.[1],
                ];
              }
            } else {
              if (keyDownEvent.key === ChartBoxNumber.KeyOne) {
                clone.checkbox = [true, false];
              } else if (keyDownEvent.key === ChartBoxNumber.KeyTwo) {
                clone.checkbox = [false, true];
              }
            }

            if (ectopicType === ECTOPIC_TYPE.ISOLATE && clone.checkbox) {
              clone.checkbox = [clone.checkbox[0]];
            } else if (
              ectopicType === ECTOPIC_TYPE.COUPLET &&
              clone.checkbox &&
              clone.checkbox[0] &&
              clone.checkbox[1]
            ) {
              clone.checkbox = [clone.checkbox[0], clone.checkbox[1]];
            }

            if (!clone.checkbox) return new Map(prev);

            const hasSomeTrue = clone.checkbox.some(
              (v: boolean | undefined) => v === true
            );
            if (hasSomeTrue) {
              resolve(clone);
              return new Map([...prev, [moveBasis.index, clone]]);
            } else {
              // checkbox all false case
              const newState = new Map(prev);
              newState.delete(moveBasis.index);
              resolve(clone);
              return new Map(newState);
            }
          });
        }).then((clone) => {
          if (typeof handleSelectedCheckboxStatus === 'function') {
            handleSelectedCheckboxStatus(clone.checkbox);
          }
        });
      });
    },
    toggleSelectAll({
      panelType,
      selectAllState,
      setCheckBoxStatus,
      handleSetSelectAll,
    }: {
      panelType: ActivePanelType;
      selectAllState: CheckBoxAll;
      setCheckBoxStatus: React.Dispatch<React.SetStateAction<CheckBoxStatus>>;
      handleSetSelectAll: React.Dispatch<
        React.SetStateAction<{
          panelType: ActivePanelType;
          selectAllState: CheckBoxAll;
        }>
      >;
    }) {
      const nextSelectAllState =
        selectAllState === CheckBoxAll.None
          ? CheckBoxAll.CheckedAll
          : CheckBoxAll.None;

      handleSetSelectAll({
        panelType,
        selectAllState: nextSelectAllState,
      });

      return;
    },
  },
  pagination({
    keyDownEvent,
    panelType,
    handleSetPage,
  }: {
    keyDownEvent: onKeyDown;
    panelType: ActivePanelType;
    handleSetPage: React.Dispatch<
      React.SetStateAction<{
        panelType: ActivePanelType;
        setPageType: POSITION_MOVE_TYPE;
      }>
    >;
  }) {
    const isLeftBracket = keyDownEvent.key === shortCut['['];
    const setPageType = isLeftBracket
      ? POSITION_MOVE_TYPE.PREV
      : POSITION_MOVE_TYPE.NEXT;

    handleSetPage({
      panelType,
      setPageType,
    });
  },
};

export function getIsEditedOfFormPanel(
  panelPatchedList: any,
  chartInfo: ChartListItem
): boolean {
  return (
    panelPatchedList.includes(chartInfo.formInfo.id) &&
    chartInfo.formInfo.formRankNumber !== 0
  );
}
export function getIsEditedOfEventPanel(
  panelPatchedList: any,
  chartInfo: ChartListItem
): boolean {
  let isEdited = false;

  if (panelPatchedList === editType.ALL) {
    isEdited = true;
  } else if (Array.isArray(panelPatchedList)) {
    isEdited = panelPatchedList.includes(chartInfo.originWaveformIndex);
  }

  return isEdited;
}

export function getSelectedClassName(
  selectedItemList: Map<number, any>,
  chartInfo: ChartListItem
): string {
  return selectedItemList.has(chartInfo.index) ? 'selected' : '';
}

export function getFormLabel(
  chartInfo: ChartListItem,
  filterShapeReviewEventGroupType: IShapeReviewSidePanelEventGroup
) {
  let formLabel;
  const formRankNumber = chartInfo.formInfo.formRankNumber;

  switch (formRankNumber) {
    case 0:
      formLabel = `Edited ${filterShapeReviewEventGroupType.label}`;
      break;
    case -1:
      formLabel = `Etc. ${filterShapeReviewEventGroupType.label}`;
      break;
    default:
      formLabel = `Form ${formRankNumber}`;
  }

  return formLabel;
}

export function getCheckConditions(selectedItem: [number, any] | undefined): {
  isCheckedFirst: boolean;
  isCheckedSecond: boolean;
} {
  let isCheckedFirst = false;
  let isCheckedSecond = false;

  if (!!selectedItem && selectedItem[1].checkbox) {
    isCheckedFirst = selectedItem[1].checkbox[0];
    isCheckedSecond = selectedItem[1].checkbox[1];
  }

  return { isCheckedFirst, isCheckedSecond };
}

export function getSelectedItem(
  selectedItemList: Map<number, any>,
  chartInfo: ChartListItem
) {
  return [...selectedItemList].find(
    (selectedItem) =>
      selectedItem[1].matrix[0] === chartInfo.matrix[0] &&
      selectedItem[1].matrix[1] === chartInfo.matrix[1]
  );
}

export function getFocusState(
  checkBoxStatus: CheckBoxStatus,
  lastClickInfo: SelectedItemInfo | undefined,
  chartInfo: ChartListItem
): boolean {
  let isFocused = false;

  if (
    checkBoxStatus.isIndeterminate === false &&
    checkBoxStatus.isChecked === true
  ) {
    isFocused = false;
  } else {
    isFocused =
      lastClickInfo?.matrix[0] === chartInfo.matrix[0] &&
      lastClickInfo?.matrix[1] === chartInfo.matrix[1];
  }
  return isFocused;
}

export function formatRRIntervalRatio(
  rrIntervalRatioArray: (number | string)[]
) {
  if (rrIntervalRatioArray.length === 1) return rrIntervalRatioArray[0];

  return `${rrIntervalRatioArray[0]} : ${rrIntervalRatioArray[1]}`;
}

export const shapeReviewState = {
  visibleArrangeRequiredTooltip: false,
};

export function getIsSelectedAll({
  selectedItemList,
  panelList,
}: {
  selectedItemList: [number, SelectedItemInfo][];
  panelList: ChartListItem[];
}) {
  const isSameAsSelectedBox = selectedItemList.length === panelList.length; // 선택된 box 개수가 패널 전체 box 개수와 같은지 판단 여부
  const isCheckBoxAllTrue = !selectedItemList.some(
    // 선택된 box 중 하나라도 checkbox가 false인 것이 있는지 판단
    (item) => item[1].checkbox && item[1].checkbox.includes(false)
  );

  const isSelectedAll =
    selectedItemList.length > 0 && isSameAsSelectedBox && isCheckBoxAllTrue;

  return isSelectedAll;
}

export function getIsIndeterminate({
  selectedItemList,
  panelList,
}: {
  selectedItemList: [number, SelectedItemInfo][];
  panelList: ChartListItem[];
}) {
  const isSelectedAll = getIsSelectedAll({
    selectedItemList,
    panelList,
  });
  const isCheckBoxHasTrue = selectedItemList.some(
    // 선택된 box 중 하나라도 checkbox가 true가 있는지 판단
    (item) => item[1].checkbox && item[1].checkbox.includes(true)
  );
  const isIndeterminate =
    selectedItemList.length > 0 && !isSelectedAll && isCheckBoxHasTrue;

  return isIndeterminate;
}

/**
 * event panel에서 event가 모두 수정되었는지 판단
 * couplet event에서 하나의 event(waveformIndex)가 수정되면 수정으로 간주
 * @param param0
 * @returns
 */
export function getIsAllEventEdited({
  poolingDataOfClickedEvent,
  newPatchedEventListOfClickedForm,
}: {
  poolingDataOfClickedEvent: [WaveformIndex, ChartListItem][];
  newPatchedEventListOfClickedForm: WaveformIndex[];
}) {
  for (let arr of poolingDataOfClickedEvent) {
    const originWaveformIndexPosition = arr[1].beats.waveformIndex.indexOf(
      arr[1].originWaveformIndex
    );
    const terminationWaveformIndexPosition = arr[1].beats.waveformIndex.indexOf(
      arr[1].terminationWaveformIndex
    );
    const wfi1 = arr[1].beats.waveformIndex[originWaveformIndexPosition];
    const wfi2 = arr[1].beats.waveformIndex[terminationWaveformIndexPosition];

    const isPatchedWfi =
      newPatchedEventListOfClickedForm.includes(wfi1) ||
      newPatchedEventListOfClickedForm.includes(wfi2);
    if (!isPatchedWfi) return false;
  }

  return true;
}

/**
 * 선택한 정렬 옵션으로 설정
 * @param currentOrderType - 선택된 Form의 가장 마지막 sort Type
 * * currentOrderType : api params
 * * ['','hr','-hr','rr-interval','-rr-interval','similarity','-similarity']
 * @returns targetSortOption | defaultSortOption
 */
//
export function getSortOptionByOrdering(currentOrderType: string) {
  const defaultSortOption: OrderingTypeSettingType = {
    valueText: '',
    optionText: '',
    tooltipTitle: '',
    queryOrderBy: '',
    ascending: true,
    isDefault: true,
  };

  if (currentOrderType !== '') {
    const targetSortOption = Object.values(
      SORT_ORDER_TYPE_OPTION_LIST_MAP
    ).find((sortOption: SortOption) => {
      return sortOption.queryOrderBy === currentOrderType;
    });
    return targetSortOption;
  }

  return defaultSortOption;
}
