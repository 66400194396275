import {
  PoolingState,
  ShapeReviewSectionArea,
} from 'constant/ShapeReviewConst';

import { Beats } from 'redux/duck/shapeReview/shapeReviewDuckType';

export function getWillBeDisplayedFormDataList({
  waveformIndexOfSelectedTarget,
  sliceInfoOfBeDisplayedFormData,
  poolingDataOfSelectedTarget,
}: {
  waveformIndexOfSelectedTarget: any;
  sliceInfoOfBeDisplayedFormData: { begin: number; end: number };
  poolingDataOfSelectedTarget: any;
}) {
  const beDisplayedWaveFormIndexList = waveformIndexOfSelectedTarget.slice(
    sliceInfoOfBeDisplayedFormData.begin,
    sliceInfoOfBeDisplayedFormData.end
  );

  let willBeDisplayedFormDataList: number[] = [];
  const millisecondTime = new Date().getTime();
  for (let index of beDisplayedWaveFormIndexList) {
    const indexData = poolingDataOfSelectedTarget.get(index);
    // indexData === 'pending': fetching 상태, indexData === undefined: 초기 설정 상태
    if (indexData !== PoolingState.PENDING && indexData !== undefined) {
      indexData.millisecondTime = millisecondTime;
      willBeDisplayedFormDataList.push(indexData);
    }
  }
  return willBeDisplayedFormDataList;
}

/**
 * 선택된 이벤트의 forms에서 beatEventDetail의 waveformIndex가 몇번째 index에 있는지 확인
 *
 * @param selectedEventFormListInfo
 * @param beatEventDetail
 * @returns
 */
export function findIndexOfBeatEventDetailInFromList(
  selectedEventFormListInfo: any,
  beatEventDetail: any
) {
  return selectedEventFormListInfo.forms.findIndex(
    (v: any) =>
      v.representativeWaveformIndex === beatEventDetail.originWaveformIndex
  );
}

export function getIndexOfNextPoolingInPrevCase(
  beginIndexOfCurrentPage: number,
  formsThumbNailWaveFormIndexOfClickedEvent: any,
  selectedEventFormPoolingData: any,
  limit: any
) {
  for (
    let cursor = beginIndexOfCurrentPage;
    cursor > (limit > 0 ? limit : 0);
    cursor--
  ) {
    const waveformIndexOfIndexInList =
      formsThumbNailWaveFormIndexOfClickedEvent[cursor];
    if (!selectedEventFormPoolingData.get(waveformIndexOfIndexInList)) {
      return cursor;
    }
  }
}

export function getIndexOfNextPooling(
  beginIndexOfCurrentPage: number,
  formsThumbNailWaveFormIndexOfClickedEvent: any,
  selectedEventFormPoolingData: any,
  limit: any
) {
  for (
    let cursor = beginIndexOfCurrentPage;
    cursor <
    (limit > formsThumbNailWaveFormIndexOfClickedEvent.length
      ? formsThumbNailWaveFormIndexOfClickedEvent.length
      : limit);
    cursor++
  ) {
    const waveformIndexOfIndexInList =
      formsThumbNailWaveFormIndexOfClickedEvent[cursor];
    if (!selectedEventFormPoolingData.get(waveformIndexOfIndexInList)) {
      return cursor;
    }
  }
}

export function getNewCurrentPageDependOnPoolingData({
  clickedEventPanelSizeInfo,
  newCurrentPage,
  action,
  state,
  clickedEventType,
  formPanelDetailOfFirstIndexOfLastSelectedSection,
  clickedTargetPoolingData,
  clickedEventCurrentPage,
}: {
  clickedEventPanelSizeInfo: any;
  newCurrentPage: number;
  action: { panelType: ShapeReviewSectionArea };
  state: any;
  clickedEventType: any;
  formPanelDetailOfFirstIndexOfLastSelectedSection: any;
  clickedTargetPoolingData: any;
  clickedEventCurrentPage: any;
}): number {
  const nextPageFirstItemIndex =
    clickedEventPanelSizeInfo.pageSize * (newCurrentPage - 1);
  let waveFormIndexOfNextPageFirstItemIndex;
  if (action.panelType === ShapeReviewSectionArea.FORMS) {
    waveFormIndexOfNextPageFirstItemIndex =
      state.clusterFormInfoList.data[clickedEventType]
        .formsThumbNailWaveFormIndex[nextPageFirstItemIndex];
  } else if (action.panelType === ShapeReviewSectionArea.EVENTS) {
    waveFormIndexOfNextPageFirstItemIndex =
      state.waveformIndexListInfoOfForms.data[clickedEventType][
        formPanelDetailOfFirstIndexOfLastSelectedSection.formInfo.id
      ].onsetWaveformIndexListOfForm[nextPageFirstItemIndex];
  }

  const hasPoolingData = clickedTargetPoolingData.get(
    waveFormIndexOfNextPageFirstItemIndex
  );

  if (!hasPoolingData || hasPoolingData === PoolingState.PENDING) {
    newCurrentPage = clickedEventCurrentPage;
  }
  return newCurrentPage;
}

/**
 * 패딩된 배열을 반환합니다.
 * @param arr - 검증할 배열
 * @param targetLength - 최소 배열 길이
 * @param paddingLocation - 패딩을 추가할 위치 ('start' 또는 'end')
 * @returns 검증할 배열의 길이가 targetLength보다 작을 경우, 배열의 앞 또는 뒤에 0을 채운 배열을 반환합니다.
 */
export function padArrayWithZeros({
  arr,
  targetLength,
  paddingLocation,
}: {
  arr: number[];
  targetLength: number;
  paddingLocation: 'start' | 'end';
}): number[] {
  const currentLength = arr.length;

  if (currentLength >= targetLength) return arr;

  const zerosToAdd = targetLength - currentLength;
  const zeroArray: number[] = new Array(zerosToAdd).fill(0);

  if (paddingLocation === 'start') return [...zeroArray, ...arr];
  if (paddingLocation === 'end') return [...arr, ...zeroArray];
  return arr;
}

/**
 * clusterFormDetail & poolingData[FORM] 업데이트 map 생성  함수
 *
 * @param poolingDataEventsMap - 선택된 이벤트 타입의 poolingData[EVENTS]
 * @param selectedOriginWaveformIndex - 선택된 events의 originWaveformIndex
 * @param newBeatsOrigin - 편집 이후 beatsOrigin
 * @returns 선택된 Events의 beatsOrigin을 Form에 맞게 map 형태 데이터를 반환합니다.(4초 형태)
 */
export function getTargetMapFromPoolingEvents({
  poolingDataEventsMap,
  selectedOriginWaveformIndex,
  newBeatsOrigin,
}: {
  poolingDataEventsMap: any;
  selectedOriginWaveformIndex: number;
  newBeatsOrigin: Beats;
}) {
  let updatedMapForPoolingDataOfForm;

  Object.keys(poolingDataEventsMap).find((key: string) => {
    const valueMap = poolingDataEventsMap[key];
    if (valueMap?.has(selectedOriginWaveformIndex)) {
      const onsetMapIndex = newBeatsOrigin.waveformIndex.findIndex(
        (v) => v >= selectedOriginWaveformIndex - 500
      );
      const terminationMapIndex = newBeatsOrigin.waveformIndex.findIndex(
        (v) => v >= selectedOriginWaveformIndex + 500
      );
      const { beatType, isEdited, hr, waveformIndex } = newBeatsOrigin;

      updatedMapForPoolingDataOfForm = {
        beatType: beatType.slice(onsetMapIndex, terminationMapIndex),
        isEdited: isEdited.slice(onsetMapIndex, terminationMapIndex),
        hr: hr.slice(onsetMapIndex, terminationMapIndex),
        waveformIndex: waveformIndex.slice(onsetMapIndex, terminationMapIndex),
      };
    }
  });

  return { updatedMapForPoolingDataOfForm };
}

/**
 * poolingData[FORMS] 업데이트 함수
 *
 * @param poolingDataFormsMap - 선택된 이벤트 타입의 poolingData[FORMS]
 * @param selectedOriginWaveformIndex - 선택된 events의 originWaveformIndex
 * @param updatedMap - return - getTargetMapFromPoolingEvents()
 * @param millisecondTime - new Date()
 * @returns 업데이트 된 PoolingData[FORMS]
 */

export function getUpdatedFormsPoolingData({
  poolingDataFormsMap,
  selectedOriginWaveformIndex,
  updatedMapForPoolingDataOfForm,
  millisecondTime,
}: {
  poolingDataFormsMap: any;
  selectedOriginWaveformIndex: number;
  updatedMapForPoolingDataOfForm: Beats;
  millisecondTime: number;
}) {
  const resultMap = new Map();
  for (const [key, value] of poolingDataFormsMap) {
    if (selectedOriginWaveformIndex === key) {
      value.beats = updatedMapForPoolingDataOfForm;
      value.millisecondTime = millisecondTime;
      resultMap.set(key, value);
    } else {
      resultMap.set(key, value);
    }
  }
  return resultMap;
}

/**
 * poolingData[EVENTS] 업데이트 함수
 *
 * @param poolingDataEventsMap - 선택된 이벤트 타입의 poolingData[EVENTS]
 * @param selectedOriginWaveformIndex - 선택된 events의 originWaveformIndex
 * @param eventsKeys - 선택된 poolingData[EVENTS]의 Key (events의 originWaveformIndex)
 * @param millisecondTime - new Date()
 * @param newBeatsOrigin - 편집 이후 beatsOrigin
 * @returns 업데이트 된 PoolingData[EVENTS]
 */
export function getUpdatedEventsPoolingData({
  poolingDataEventsMap,
  selectedOriginWaveformIndex,
  eventsKeys,
  millisecondTime,
  newBeatsOrigin,
}: {
  poolingDataEventsMap: any;
  selectedOriginWaveformIndex: number;
  eventsKeys: string[];
  millisecondTime: number;
  newBeatsOrigin: Beats;
}) {
  const result = new Map();
  for (const key of eventsKeys) {
    const valueMap = poolingDataEventsMap[key];
    if (valueMap && valueMap.has(selectedOriginWaveformIndex)) {
      const value = valueMap.get(selectedOriginWaveformIndex);
      value.beats = newBeatsOrigin;
      value.millisecondTime = millisecondTime;
      result.set(key, valueMap);
    } else {
      result.set(key, valueMap);
    }
  }

  const resultObject = {} as any;
  for (const [key, value] of result) {
    resultObject[key] = value;
  }

  return resultObject;
}
export function updatePoolingBeatType({
  poolingData,
  succeedWaveformIndexList,
  reqBeatType,
  formId,
  updatedPatchBeatByWaveFormIndexes,
}: {
  poolingData: any;
  succeedWaveformIndexList: any;
  reqBeatType: any;
  formId: any;
  updatedPatchBeatByWaveFormIndexes: any;
}) {
  const indexOfOnsetOriginWaveformIndex =
    poolingData.beats.waveformIndex.indexOf(poolingData.originWaveformIndex);
  const indexOfTerminationOriginWaveformIndex =
    poolingData.beats.waveformIndex.indexOf(
      poolingData.terminationWaveformIndex
    );

  if (succeedWaveformIndexList.includes(poolingData.originWaveformIndex)) {
    poolingData.beatType = reqBeatType;
    poolingData.beats.beatType[indexOfOnsetOriginWaveformIndex] = reqBeatType;
    updatedPatchBeatByWaveFormIndexes[formId] = [
      ...new Set(
        [
          ...(updatedPatchBeatByWaveFormIndexes?.[formId] || []),
          poolingData.originWaveformIndex,
        ].filter(Number)
      ),
    ];
  }

  if (
    succeedWaveformIndexList.includes(
      poolingData.beats.waveformIndex[indexOfTerminationOriginWaveformIndex]
    )
  ) {
    poolingData.beatType = reqBeatType;
    poolingData.beats.beatType[indexOfTerminationOriginWaveformIndex] =
      reqBeatType;
    updatedPatchBeatByWaveFormIndexes[formId] = [
      ...new Set(
        [
          ...(updatedPatchBeatByWaveFormIndexes?.[formId] || []),
          poolingData.originWaveformIndex,
        ].filter(Number)
      ),
    ];
  }
}

export function isFormIdInList(formIds: number[], selectedFormId: number) {
  return formIds.includes(selectedFormId);
}

/**
 * poolingData[Form] 삭제 함수
 *
 * @param poolingDataEventsMap - 선택된 이벤트 타입의 poolingData[EVENTS]
 * @param deleteTargetWaveformIndexList - 삭제 할 waveformIndex List
 * @returns 삭제된 PoolingData[FORMS]
 */

export function deleteBeatsByFormIdUpdatePoolingBeatType({
  poolingData,
  deleteTargetWaveformIndexList,
}: {
  poolingData: any;
  deleteTargetWaveformIndexList: any;
}) {
  const indexOfOnsetWaveformIndex = poolingData.beats.waveformIndex.indexOf(
    poolingData.originWaveformIndex
  );
  const isOnsetDeletableWaveform =
    deleteTargetWaveformIndexList.includes(poolingData.originWaveformIndex) &&
    indexOfOnsetWaveformIndex !== -1;

  if (isOnsetDeletableWaveform) {
    poolingData.beats.beatType.splice(indexOfOnsetWaveformIndex, 1);
    poolingData.beats.hr.splice(indexOfOnsetWaveformIndex, 1);
    poolingData.beats.isEdited.splice(indexOfOnsetWaveformIndex, 1);
    poolingData.beats.waveformIndex.splice(indexOfOnsetWaveformIndex, 1);
  }

  // Form 삭제 기능 termination 정보는 onset을 삭제하는 경우에 삭제된 이후의 상태값을 기준으로 termination 위치에 삭제를 해야하기 때문에
  // onsetOriginWaveformIndex 삭제 이후 terminationWaveformIndex의 위치를 찾는다
  const indexOfTerminationWaveformIndex =
    poolingData.beats.waveformIndex.indexOf(
      poolingData.terminationWaveformIndex
    );
  const isTerminationDeletableWaveform =
    deleteTargetWaveformIndexList.includes(
      poolingData.terminationWaveformIndex
    ) && indexOfTerminationWaveformIndex !== -1;

  if (isTerminationDeletableWaveform) {
    poolingData.beats.beatType.splice(indexOfTerminationWaveformIndex, 1);
    poolingData.beats.hr.splice(indexOfTerminationWaveformIndex, 1);
    poolingData.beats.isEdited.splice(indexOfTerminationWaveformIndex, 1);
    poolingData.beats.waveformIndex.splice(indexOfTerminationWaveformIndex, 1);
  }
}
/**
 * poolingData[EVENTS] 삭제 함수
 *
 * @param poolingDataEventsMap - 선택된 이벤트 타입의 poolingData[EVENTS]
 * @param eventsKeys - 선택된 poolingData[EVENTS]의 Key (events의 originWaveformIndex)
 * @param millisecondTime - new Date()
 * @param targetWaveformIndexPosition - 편집 이후 beatsOrigin
 * @returns 삭제된 PoolingData[EVENTS]
 */

export function getUpdatedDeletedEventsPoolingData({
  poolingDataEventsMap,
  eventsKeys,
  millisecondTime,
  targetWaveformIndexPosition,
}: {
  poolingDataEventsMap: any;
  eventsKeys: any;
  millisecondTime: any;
  targetWaveformIndexPosition: any;
}) {
  const result = new Map();
  for (const eventKey of eventsKeys) {
    const valueMap = poolingDataEventsMap[eventKey];
    for (let deleteTarget in targetWaveformIndexPosition) {
      const hasDeleteTargetInValueMap = valueMap.has(
        targetWaveformIndexPosition[deleteTarget].targetWaveformIndex
      );

      if (hasDeleteTargetInValueMap) {
        const value = valueMap.get(
          targetWaveformIndexPosition[deleteTarget].targetWaveformIndex
        );
        if (Number(deleteTarget) === value.originWaveformIndex) {
          const originWaveformIndex = value.beats.waveformIndex.indexOf(
            value.originWaveformIndex
          );
          value.beats.hr.splice(originWaveformIndex, 1);
          value.beats.beatType.splice(originWaveformIndex, 1);
          value.beats.isEdited.splice(originWaveformIndex, 1);
          value.beats.waveformIndex.splice(originWaveformIndex, 1);
        }

        if (Number(deleteTarget) === value.terminationWaveformIndex) {
          const terminationWaveformIndex = value.beats.waveformIndex.indexOf(
            value.terminationWaveformIndex
          );
          value.beats.hr.splice(terminationWaveformIndex, 1);
          value.beats.beatType.splice(terminationWaveformIndex, 1);
          value.beats.isEdited.splice(terminationWaveformIndex, 1);
          value.beats.waveformIndex.splice(terminationWaveformIndex, 1);
        }
        value.millisecondTime = millisecondTime;

        result.set(eventKey, valueMap);
      } else {
        result.set(eventKey, valueMap);
      }
    }
  }
  const resultObject = {};
  for (const [key, value] of result) {
    resultObject[key] = value;
  }
  return resultObject;
}
