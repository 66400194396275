import { ECG_CHART_UNIT, TEN_SEC_STRIP_DETAIL } from 'constant/ChartEditConst';
import LocalStorageKey from 'constant/LocalStorageKey';

import LocalStorageManager from 'manager/LocalStorageManager';

type handleOpenEventReviewInNewTabParam = {
  tabType: string;
  dataLength: number;
  typeOfCenterWI: string;
  onsetWaveformIdx: number;
};
/** 10초 Strip 에서 조회 중인 지점을 새탭에서 Event Review 화면으로 제공 */
export const handleOpenEventReviewInNewTab = ({
  tabType,
  dataLength,
  typeOfCenterWI,
  onsetWaveformIdx,
}: handleOpenEventReviewInNewTabParam) => {
  if (tabType === TEN_SEC_STRIP_DETAIL.TAB.EVENT_REVIEW || dataLength === 0) {
    return;
  }
  const { origin, pathname } = window.location;
  const newBeatReviewSidePanelState = [
    {
      type: typeOfCenterWI,
      position: null,
      waveformIndex:
        onsetWaveformIdx + ECG_CHART_UNIT.HALF_TEN_SEC_WAVEFORM_IDX,
      timeEventId: null,
    },
  ];
  const currentTidEventReviewUrl = origin + pathname + '?tab=event-review';

  LocalStorageManager.setItem(
    LocalStorageKey.SIDE_PANEL_STATE,
    newBeatReviewSidePanelState
  );

  window.open(currentTidEventReviewUrl, '_blank');
};

interface ReviewState {
  isHrReview: boolean;
  isEventReview: boolean;
  isBeatReview: boolean;
  isShapeReview: boolean;
}

export function generateReviewState(tenSecStripTabValue: string): ReviewState {
  const reviewTypes: { [key: string]: keyof ReviewState } = {
    HR_REVIEW: 'isHrReview',
    EVENT_REVIEW: 'isEventReview',
    BEAT_REVIEW: 'isBeatReview',
    SHAPE_REVIEW: 'isShapeReview',
  };

  const reviewState: Partial<ReviewState> = {};
  for (const [key, value] of Object.entries(reviewTypes)) {
    reviewState[value] = tenSecStripTabValue === TEN_SEC_STRIP_DETAIL.TAB[key];
  }

  return reviewState as ReviewState;
}
