import { useEffect, useRef, useState } from 'react';
import { batch, useDispatch } from 'react-redux';
import ReactDOM from 'react-dom';

import Const from 'constant/Const';
import {
  ChartBoxNumber,
  CheckBoxAll,
  SHAPE_REVIEW_CHART_INFO,
  ShapeReviewRawAndEventCalledCase,
  ShapeReviewSectionArea,
  ShapeReviewState,
  shortCut,
  UI_INTERACTION,
} from 'constant/ShapeReviewConst';

import {
  getCheckBoxByEctopicType,
  getIsSelectedAll,
  uiInteractionMap,
} from 'util/shapeReview/shapeReviewUtil';

import useKeyEventListener, {
  keyboardEventInterfaceMap,
} from 'component/hook/useKeyEventListener';
import useShallowEqualSelector from 'component/hook/useShallowEqualSelector';

import { ChartItemRectangle } from 'redux/container/fragment/test-result/shape-review/ShapeReviewFragmentContainer';

import { divRef, onClickDivMouseEvent, onKeyDown } from '@type/reactTypes';
import {
  setSelectAll,
  setPanelSize,
  setLastSelectedSectionInfo as setLastSelectedSectionInfoHandle,
  setSelectedItemList as setSelectedItemListHandle,
  setPage,
  selectEventPoolingStateOfSelectedEvent,
  selectClickedInfo,
  selectActivePanel,
  selectPatchBeatByWaveFormIndexes,
  selectPatchBeatByFormIds,
  setSelectedCheckboxStatus,
} from 'redux/duck/shapeReviewDuck';
import {
  ChartListItem,
  Checkbox,
  LastSelectedSectionInfo,
  PatchBeatByFormIds,
  PatchBeatByWaveFormIndexes,
  SelectedItemInfo,
  SetLastSelectedSectionInfo,
} from 'redux/duck/shapeReview/shapeReviewDuckType';
import { EctopicType } from '@type/ecgEventType/baseEventType';

import useIsMounted from './useIsMounted';

export interface BeatEventSelectionInfo {
  columnNumber: number;
  rowNumber: number;
}

interface SetPanelSize extends BeatEventSelectionInfo {
  panelType: Extract<ShapeReviewSectionArea, 'FORMS' | 'EVENTS'>;
}

function useChartList({
  panelType,
  panelList,
  selectedInfo,
  setCheckBoxStatus,
}: {
  panelType: Extract<ShapeReviewSectionArea, 'FORMS' | 'EVENTS'>;
  panelList: any;
  selectedInfo: any;
  setCheckBoxStatus: any;
}) {
  const dispatch = useDispatch();

  const activePanel = useShallowEqualSelector((state: any) =>
    selectActivePanel(state)
  ) as string;

  const { ectopicType } = useShallowEqualSelector((state: any) =>
    selectClickedInfo(state)
  ) as { ectopicType: EctopicType };

  const selectAllState = useShallowEqualSelector(
    (state: any) =>
      state.shapeReviewReducer.shapeReviewState[ShapeReviewState.SELECT_ALL][
        panelType
      ]
  ) as CheckBoxAll;
  const { pending: patchBeatByFormIdsPending } = useShallowEqualSelector(
    selectPatchBeatByFormIds
  ) as { pending: Pick<PatchBeatByFormIds, 'pending'> };

  const { pending: patchBeatByWaveFormIndexesPending } =
    useShallowEqualSelector(selectPatchBeatByWaveFormIndexes) as {
      pending: Pick<PatchBeatByWaveFormIndexes, 'pending'>;
    };

  const { pending: pendingOfEventPoolingStateOfSelectedEvent }: any =
    useShallowEqualSelector((state: any) =>
      selectEventPoolingStateOfSelectedEvent(state)
    );

  // 기본 클릭, 기본 이동, 체크박스 직접 클릭, 단축키 (1,2)가 동작이 되면 상태를 업데이트한다.(selectedCheckboxStatus)
  const lastChangedCheckboxStatus: any = useShallowEqualSelector(
    (state: any) =>
      state.shapeReviewReducer.shapeReviewState.selectedCheckboxStatus
  );

  const handleSetPanelSize = ({
    panelType,
    columnNumber,
    rowNumber,
  }: SetPanelSize) => {
    return dispatch(setPanelSize({ panelType, columnNumber, rowNumber }));
  };

  const handleSetSelectAll = ({ panelType, selectAllState }: any) => {
    return dispatch(setSelectAll({ panelType, selectAllState }));
  };

  const handleSetPage = ({ panelType, setPageType, value }: any) => {
    return dispatch(
      setPage({
        panelType,
        setPageType,
        value,
      })
    );
  };

  const handleLastSelectedSectionInfo = ({
    triggerType,
    panelType,
    lastSelectedSectionInfo,
  }: SetLastSelectedSectionInfo) => {
    return dispatch(
      setLastSelectedSectionInfoHandle({
        triggerType,
        panelType,
        lastSelectedSectionInfo,
      })
    );
  };

  const handleSelectedItemList = ({
    panelType,
    selectedItemList,
  }: {
    panelType: Extract<ShapeReviewSectionArea, 'FORMS' | 'EVENTS'>;
    selectedItemList: any;
  }) => {
    return dispatch(setSelectedItemListHandle({ panelType, selectedItemList }));
  };

  // checkbox 상태 변경 redux function(이벤트 클릭, 이벤트의 체크박스 변경시에만 호출)
  const handleSelectedCheckboxStatus = (checkboxStatus: Checkbox) => {
    dispatch(setSelectedCheckboxStatus(checkboxStatus));
  };

  const isMounted = useIsMounted();
  const triggerType = useRef<ShapeReviewRawAndEventCalledCase>(
    ShapeReviewRawAndEventCalledCase.CLICK_EVENT
  );
  const gridLayoutRef = useRef() as divRef;
  const chartItemRectangle: ChartItemRectangle = {
    height: SHAPE_REVIEW_CHART_INFO.MIN_HEIGHT,
    width: SHAPE_REVIEW_CHART_INFO.MIN_WIDTH,
  };
  const [chartItemInfo, setChartItemInfo] = useState<BeatEventSelectionInfo>({
    columnNumber: 0,
    rowNumber: 0,
  });
  const [chartList, setChartList] = useState<ChartListItem[]>([]);

  // variable for 'focus feature'
  const [lastSelectedSectionInfo, setLastSelectedSectionInfo] =
    useState<LastSelectedSectionInfo>([undefined, undefined]);
  // variable for 'select List feature'; List means plural
  const [selectedItemList, setSelectedItemList] = useState<
    Map<number, SelectedItemInfo>
  >(new Map());

  // keydown eventListener: shape-review 키보드 단축키
  useKeyEventListener(
    Const.EVENT_TYPE.KEYDOWN,
    [
      Const.KEY_MAP.ARROW_LEFT,
      Const.KEY_MAP.ARROW_RIGHT,
      Const.KEY_MAP.ARROW_UP,
      Const.KEY_MAP.ARROW_DOWN,
      ChartBoxNumber.KeyOne,
      ChartBoxNumber.KeyTwo,
      shortCut.a,
      shortCut.n,
      shortCut.A,
      shortCut.N,
      shortCut['['],
      shortCut[']'],
    ],
    onKeyDown,
    keyboardEventInterfaceMap.key
  );

  // setting for "chartItemInfo" : column, row number
  useEffect(() => {
    if (gridLayoutRef.current) {
      gridLayoutRef.current.style.flex = '1';
    }
    const gridLayoutRect = gridLayoutRef.current?.getBoundingClientRect();
    if (gridLayoutRect && gridLayoutRef.current) {
      gridLayoutRef.current.style.height = String(gridLayoutRect.height) + 'px';
      gridLayoutRef.current.style.flex = 'none';
    }
    const gridLayoutRectWidth = gridLayoutRect?.width;
    const gridLayoutRectHeight = gridLayoutRect?.height;

    if (!gridLayoutRectWidth || !gridLayoutRectHeight) {
      return;
    }

    let beatEventSectionColumnNumber = 0;
    let beatEventSectionRowNumber = 0;
    let gridLayoutRectWidthWithoutPadding =
      gridLayoutRectWidth - SHAPE_REVIEW_CHART_INFO.PADDING_LEFT_RIGHT * 2;
    let gridLayoutRectHeightWithoutPadding =
      gridLayoutRectHeight - SHAPE_REVIEW_CHART_INFO.PADDING_UP_DOWN * 2;

    while (gridLayoutRectWidthWithoutPadding >= chartItemRectangle.width) {
      beatEventSectionColumnNumber++;
      gridLayoutRectWidthWithoutPadding -= chartItemRectangle.width + 10;
    }
    while (gridLayoutRectHeightWithoutPadding >= chartItemRectangle.height) {
      beatEventSectionRowNumber++;
      gridLayoutRectHeightWithoutPadding -= chartItemRectangle.height + 10;
    }

    setChartItemInfo({
      columnNumber: beatEventSectionColumnNumber,
      rowNumber: beatEventSectionRowNumber,
    });
    handleSetPanelSize({
      panelType,
      columnNumber: beatEventSectionColumnNumber,
      rowNumber: beatEventSectionRowNumber,
    });
  }, []);

  // setting for "chartList"
  useEffect(() => {
    if (!chartItemInfo.columnNumber || !chartItemInfo.rowNumber) {
      return;
    }

    const divideNumber = chartItemInfo.columnNumber;
    setChartList(
      panelList.map((v: any, i: number) => {
        v.index = i;
        v.matrix = [
          Number.parseInt(i / divideNumber + '', 10),
          i % divideNumber,
        ];
        return v;
      })
    );
  }, [panelList]);

  // 전체 선택 기능
  useEffect(() => {
    if (selectAllState === CheckBoxAll.None) {
      return setSelectedItemList(new Map([]));
    } else if (selectAllState === CheckBoxAll.CheckedAll) {
      const copyChartList: any = [...chartList];
      const result = copyChartList.map((v: any) => {
        v.checkbox = getCheckBoxByEctopicType(ectopicType, v, panelType);
        return v;
      });

      const newMap = new Map();
      for (let i in result) {
        newMap.set(Number(i), result[i]);
      }
      return setSelectedItemList(newMap);
    }
  }, [selectAllState]);

  // 선택 state를 Global -> Local
  useEffect(() => {
    ReactDOM.unstable_batchedUpdates(() => {
      setLastSelectedSectionInfo(selectedInfo.lastSelectedSectionInfo);
      setSelectedItemList(selectedInfo.selectedItemList);
    });
  }, [selectedInfo]);

  // 선택 state를 Local -> Global
  // setting setSelectAll, lastSelectedSectionInfo, selectedItemList
  useEffect(() => {
    if (!isMounted) {
      return;
    }

    let selectAllState: any;
    const copySelectedItemList = [...selectedItemList];

    const isSelectedAll = getIsSelectedAll({
      selectedItemList: copySelectedItemList,
      panelList,
    });

    if (isSelectedAll) {
      selectAllState = CheckBoxAll.CheckedAll;
    } else {
      selectAllState = CheckBoxAll.None;
    }

    batch(() => {
      handleSetSelectAll({
        panelType,
        selectAllState: selectAllState,
      });
      handleSelectedItemList({ panelType, selectedItemList });
      handleLastSelectedSectionInfo({
        triggerType: triggerType.current,
        panelType,
        lastSelectedSectionInfo,
      });
    });
  }, [lastSelectedSectionInfo, selectedItemList]);

  // event: onClick
  function onClickGridItem(
    e: onClickDivMouseEvent,
    clickedData: SelectedItemInfo
  ) {
    const { shiftKey, ctrlKey, metaKey } = e;
    if ((e.target as HTMLDivElement).tagName === 'INPUT') return;

    /**
     * # click 3가지 case
     * # case1. click
     * # case2. shift + click
     * # case3. ctrl + click
     */
    triggerType.current = ShapeReviewRawAndEventCalledCase.CLICK_EVENT;

    if (!shiftKey && !metaKey && !ctrlKey) {
      uiInteractionMap[UI_INTERACTION.CLICK]({
        clickedData,
        ectopicType,
        panelType,
        setSelectedItemList,
        setLastSelectedSectionInfo,
      });
      handleSelectedCheckboxStatus(clickedData.checkbox);
    }

    if (shiftKey) {
      uiInteractionMap[UI_INTERACTION.SHIFT_CLICK]({
        chartList,
        clickedData,
        ectopicType,
        chartItemInfo,
        selectedItemList,
        lastSelectedSectionInfo,
        panelType,
        lastChangedCheckboxStatus,
        setSelectedItemList,
        setLastSelectedSectionInfo,
      });
    }

    if (metaKey || ctrlKey) {
      uiInteractionMap[UI_INTERACTION.META_CLICK]({
        clickedData,
        ectopicType,
        panelType,
        setSelectedItemList,
        setLastSelectedSectionInfo,
      });
    }
  }

  // event: onKeyDown
  function onKeyDown(keyDownEvent: onKeyDown) {
    try {
      if (activePanel !== panelType) {
        return;
      }

      if ((keyDownEvent.target as HTMLDivElement).tagName === 'INPUT') {
        return;
      }

      keyDownEvent.stopPropagation();
      keyDownEvent.preventDefault();

      if (pendingOfEventPoolingStateOfSelectedEvent || panelList.length === 0) {
        return;
      }

      // feature: pagination
      const isPaginationKey =
        keyDownEvent.key === shortCut['['] ||
        keyDownEvent.key === shortCut[']'];
      if (isPaginationKey) {
        return uiInteractionMap['pagination']({
          keyDownEvent,
          panelType,
          handleSetPage,
        });
      }

      // feature: 전체 선택, 해제
      const isToggleSelectAllKey =
        (keyDownEvent.ctrlKey || keyDownEvent.metaKey) &&
        (keyDownEvent.key === shortCut.a || keyDownEvent.key === shortCut.A);

      if (isToggleSelectAllKey) {
        return uiInteractionMap.checkbox['toggleSelectAll']({
          panelType,
          selectAllState,
          setCheckBoxStatus,
          handleSetSelectAll,
        });
      }

      // feature: shift + 키보드 방향키, Ctrl + 키보드 방향키
      const isShiftClick = lastSelectedSectionInfo[1] !== undefined;
      const moveBasis: SelectedItemInfo | undefined =
        lastSelectedSectionInfo[isShiftClick ? 1 : 0];

      // forms, events panel에만 focus가 있고 아무것도 차트박스에 focus, select 안되어 있는 상태에서의 동작
      const isMoveInit = !moveBasis;
      // feature: 체크 박스 선택(onset, termination)
      const isSelectCheckBox =
        keyDownEvent.key === ChartBoxNumber.KeyOne ||
        keyDownEvent.key === ChartBoxNumber.KeyTwo;

      if (isMoveInit) {
        return uiInteractionMap.moveFeature.moveInit({
          keyDownEvent,
          setSelectedItemList,
          setLastSelectedSectionInfo,
        });
      }
      if (isSelectCheckBox) {
        return uiInteractionMap.checkbox.selectCheckBox({
          keyDownEvent,
          ectopicType,
          moveBasis,
          setSelectedItemList,
          setLastSelectedSectionInfo,
          handleSelectedCheckboxStatus,
        });
      }

      return uiInteractionMap.moveFeature.move({
        keyDownEvent,
        triggerType,
        chartList,
        chartItemInfo,
        moveBasis,
        ectopicType,
        selectedItemList,
        lastSelectedSectionInfo,
        lastChangedCheckboxStatus,
        setSelectedItemList,
        setLastSelectedSectionInfo,
        panelType,
        handleSetPage,
        handleSelectedCheckboxStatus,
      });
    } catch (error) {
      console.error('error in select feature', error);
    }
  }
  return {
    gridLayoutRef,
    chartItemRectangle,
    //
    chartList,
    //
    selectedItemList,
    lastSelectedSectionInfo,
    setSelectedItemList,
    setLastSelectedSectionInfo,
    //
    onClickGridItem,
  };
}

export default useChartList;
