import styled from 'styled-components';

import {
  SHAPE_REVIEW_CHART_INFO,
  ShapeReviewSectionArea,
} from 'constant/ShapeReviewConst';
import { rawAndEventCalledCase } from 'constant/ChartEditConst';
import { EVENT_GROUP_TYPE, SIDE_PANEL_EVENT_GROUP } from 'constant/EventConst';

import {
  getFormLabel,
  getFocusState,
  getSelectedItem,
  getSelectedClassName,
  getCheckConditions,
  getIsEditedOfFormPanel,
} from 'util/shapeReview/shapeReviewUtil';

import {
  ChartItemRectangle,
  PanelListFragmentProps,
} from 'redux/container/fragment/test-result/shape-review/ShapeReviewFragmentContainer';

import ProgressFragment from 'component/fragment/ProgressFragment';

import { InfoIconWrapper } from './FormsPanelFooterFragment';

import ChartFormsInfoFragment from '../share/chart/ChartFormsInfoFragment';
import GridItem from '../share/chart/GridItem';

export interface IShapeReviewSidePanelEventGroup {
  // type: typeof EventConstTypes;
  type: any; // todo: jyoon(240625) - type: baseEventType > EventConstTypes 변경 필요
  label: string;
  beatType: number;
  ectopicType: string;
  eventSection: string;
}

export const GridItemWrapper = styled.div<{
  key: number;
}>`
  :hover {
    ${InfoIconWrapper} {
      display: block;
    }
  }
  :not(:hover) {
    ${InfoIconWrapper} {
      display: none;
    }
  }
`;

const GridLayoutWrapper = styled.div`
  position: relative;
  padding: ${SHAPE_REVIEW_CHART_INFO.PADDING_UP_DOWN}px
    ${SHAPE_REVIEW_CHART_INFO.PADDING_LEFT_RIGHT}px;
  border-top: 1px solid ${(props) => props.theme.color.COOL_GRAY_30};
  z-index: 9;
`;

const BlockFragment = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
`;

const GridLayoutItemWrapper = styled.div<{
  chartItemRectangle: ChartItemRectangle;
}>`
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(${SHAPE_REVIEW_CHART_INFO.MIN_WIDTH}px, 1fr)
  );
  grid-template-rows: repeat(
    auto-fill,
    minmax(${SHAPE_REVIEW_CHART_INFO.MIN_HEIGHT}px, 1fr)
  );
  align-content: space-between;
  justify-content: space-around;

  grid-column-gap: 10px;
  grid-row-gap: 10px;
  width: 100%;
  height: 100%;
`;

function FormsPanelListFragment({
  // props state
  checkBoxStatus,
  // useChartList Hook state
  gridLayoutRef,
  chartList,
  chartItemRectangle,
  selectedItemList,
  lastSelectedSectionInfo,
  setSelectedItemList,
  setLastSelectedSectionInfo,
  // redux state
  activePanel,
  clickedInfo,
  panelPatchedList,
  poolingStateOfPanel,
  isCoupletEctopicTypeClicked,
  pendingOfEventPoolingStateOfSelectedEvent,
  //
  onClickGridItem,
}: PanelListFragmentProps) {
  const isPendingCase =
    poolingStateOfPanel.calledType === rawAndEventCalledCase.CLICK_EVENT ||
    poolingStateOfPanel.calledType === rawAndEventCalledCase.POSITION_JUMP;
  const shapeReviewEventGroupType = SIDE_PANEL_EVENT_GROUP[
    EVENT_GROUP_TYPE.SHAPE
  ] as Array<IShapeReviewSidePanelEventGroup>;
  const filterShapeReviewEventGroupType = shapeReviewEventGroupType.filter(
    (v) => v.type === clickedInfo?.eventType
  )[0];

  const isShiftClick = lastSelectedSectionInfo[1] !== undefined;
  const lastClickInfo = lastSelectedSectionInfo[isShiftClick ? 1 : 0];
  return (
    <GridLayoutWrapper
      className="FormsPanel gridLayoutWrapper"
      ref={gridLayoutRef}>
      {pendingOfEventPoolingStateOfSelectedEvent && <BlockFragment />}
      {chartList.length === 0 &&
        isPendingCase &&
        poolingStateOfPanel &&
        poolingStateOfPanel.pending === true && <ProgressFragment />}
      {chartList.length !== 0 &&
        poolingStateOfPanel &&
        poolingStateOfPanel.calledType !==
          rawAndEventCalledCase.CLICK_EVENT && (
          <GridLayoutItemWrapper
            className="gridLayoutItemWrapper"
            chartItemRectangle={chartItemRectangle}>
            {chartList.map((chartInfo, i) => {
              const isEdited = getIsEditedOfFormPanel(
                panelPatchedList,
                chartInfo
              );
              const isFocused = getFocusState(
                checkBoxStatus,
                lastClickInfo,
                chartInfo
              );

              const selectedItem = getSelectedItem(selectedItemList, chartInfo);
              const isSelected = !!selectedItem;
              const { isCheckedFirst, isCheckedSecond } =
                getCheckConditions(selectedItem);

              const selectedClass = getSelectedClassName(
                selectedItemList,
                chartInfo
              );
              const formLabel = getFormLabel(
                chartInfo,
                filterShapeReviewEventGroupType
              );

              return (
                <GridItemWrapper
                  key={chartInfo.index + chartInfo.millisecondTime}>
                  <GridItem
                    gridType={ShapeReviewSectionArea.FORMS}
                    selectedClass={selectedClass}
                    activePanel={activePanel}
                    clickedInfo={clickedInfo}
                    chartInfo={chartInfo}
                    //
                    isEdited={isEdited}
                    isFocused={isFocused}
                    isSelected={isSelected}
                    isCheckedFirst={isCheckedFirst}
                    isCheckedSecond={isCheckedSecond}
                    isCoupletEctopicTypeClicked={!!isCoupletEctopicTypeClicked}
                    //
                    onClickGridItem={onClickGridItem}
                    setSelectedItemList={setSelectedItemList}
                    setLastSelectedSectionInfo={setLastSelectedSectionInfo}
                    //
                    InfoFragment={
                      <ChartFormsInfoFragment
                        formId={chartInfo.index}
                        formLabel={formLabel}
                        eventCount={chartInfo.formInfo.beatCount}
                      />
                    }
                  />
                </GridItemWrapper>
              );
            })}
          </GridLayoutItemWrapper>
        )}
    </GridLayoutWrapper>
  );
}

export default FormsPanelListFragment;
