import { select } from 'redux-saga/effects';

import { EVENT_CONST_TYPES } from 'constant/EventConst';

import { calculateHeartRate } from 'util/reduxDuck/TestResultDuckUtil';

import {
  selectRecordingTime,
  selectTimeEventList,
} from 'redux/duck/testResultDuck';
import { OptimisticEventDataUpdateForBeats } from '@type/optimisticUpdate/type';
import { Ms } from '@type/ecgEventType/eventUnit';
import { BeatType } from '@type/ecgEventType/baseEventType';
import { TimeEvent } from '@type/ecgEventType/eventType';
import { EventEditValidation } from '@type/optimisticUpdate/validation';

import { Validation } from './eventReview_PatchBeat';

import {
  ReqBody,
  UpdateReqOption,
  UpdateReqOptionProps,
  eventReviewOptimisticEventDataUpdateForBeatEvent,
} from '../optimisticEventDataUpdateForBeatEvent';
import { Command } from '../../eventUpdateCmdPattern';

export class PostBeat extends eventReviewOptimisticEventDataUpdateForBeatEvent {
  result: any;
  reqParam: any;

  constructor(reqParam: any) {
    super();
    this.reqParam = reqParam;
    this.result = { isValidationSuccessful: true };
  }

  *optimisticEventDataUpdate({
    updateReqOption,
    filterBeatsNEctopicList,
  }: UpdateReqOptionProps) {
    const { reqBody } = updateReqOption;
    const mergedBeatsNEctopicList: OptimisticEventDataUpdateForBeats =
      this.mergeBeatsNEctopicList(filterBeatsNEctopicList);

    // validation을 통해서 api 호출 여부를 결정
    const executeValidationResult: EventEditValidation[] =
      yield this.validateExecution({
        updateReqOption,
        mergedBeatsNEctopicList,
      });
    if (executeValidationResult.length !== 0) {
      this.result = {
        isValidationSuccessful: false,
        result: executeValidationResult,
      };
      return this.result;
    }

    // # optimistic update
    let result: OptimisticEventDataUpdateForBeats = {
      hr: [],
      beatType: [],
      waveformIndex: [],
    };
    // STEP1: beatUpdate
    result = this.updateLogic({ reqBody, mergedBeatsNEctopicList });
    // STEP2: validation
    result = yield this._validation({
      reqBody,
      updateReqOption,
      mergedBeatsNEctopicList: result,
    });

    this.result = this.setOptimisticEventDataUpdateResult({
      isValidationSuccessful: true,
      result,
    });
  }

  updateLogic({
    reqBody,
    mergedBeatsNEctopicList,
  }: {
    reqBody: ReqBody;
    mergedBeatsNEctopicList: OptimisticEventDataUpdateForBeats;
  }): OptimisticEventDataUpdateForBeats {
    const {
      hr: hrList,
      beatType: beatTypeList,
      waveformIndex: waveformIndexList,
    } = mergedBeatsNEctopicList;

    for (let idx = 0; idx < reqBody.waveformIndexes.length; idx++) {
      const updateTargetWaveform = reqBody.waveformIndexes[idx];
      const updateTargetWaveformIndexIndex: number =
        waveformIndexList.findIndex(
          (waveformIndex) => waveformIndex > updateTargetWaveform
        );

      const isPrevBeatNoise =
        beatTypeList[updateTargetWaveformIndexIndex - 1] === BeatType.NOISE;
      const isTargetBeatNoise = reqBody.beatType === BeatType.NOISE;

      beatTypeList.splice(updateTargetWaveformIndexIndex, 0, reqBody.beatType);
      waveformIndexList.splice(
        updateTargetWaveformIndexIndex,
        0,
        updateTargetWaveform
      );

      if (isTargetBeatNoise) {
        hrList.splice(updateTargetWaveformIndexIndex, 0, null); // 새로 넣을 위치에 null인 hr 추가
        hrList[updateTargetWaveformIndexIndex + 1] = null; // 뒷 비트의 hr을 null로 변경
      } else {
        const nextHR = calculateHeartRate(
          waveformIndexList[updateTargetWaveformIndexIndex + 1],
          updateTargetWaveform
        );

        if (isPrevBeatNoise) {
          // 앞비트가 노이즈인 경우, 새롭게 추가될 hr도 null임
          hrList.splice(updateTargetWaveformIndexIndex, 0, null);
          hrList[updateTargetWaveformIndexIndex + 1] = nextHR;
        } else {
          // 이전 비트가 노이즈가 아닌 경우, 현재 비트와 다음 비트의 HR을 계산
          const updateTargetHR = calculateHeartRate(
            updateTargetWaveform,
            waveformIndexList[updateTargetWaveformIndexIndex - 1]
          );
          hrList.splice(updateTargetWaveformIndexIndex, 0, updateTargetHR);
          hrList[updateTargetWaveformIndexIndex + 1] = nextHR;
        }
      }
    }

    return mergedBeatsNEctopicList;
  }

  // todo: jyoon - [240909][refactor] - validation 함수들을 하나로 통합 필요(eventReview_PostBeat.ts, beatReview_EventUpdate.ts)
  *validateExecution({
    updateReqOption,
    mergedBeatsNEctopicList,
  }: {
    updateReqOption: UpdateReqOption;
    mergedBeatsNEctopicList: OptimisticEventDataUpdateForBeats;
  }): Generator<any, any, unknown> | boolean {
    // # validation List
    //  * validation1: prevent over 400 bpm when add beat
    //  * validation2: prevent add s beat in a-fib event

    let result: { type: string; msg: string }[] = [];
    const { reqBody } = updateReqOption;

    // validation1: prevent over 400 bpm when add beat
    const comparisonWaveformIndexList = mergedBeatsNEctopicList.waveformIndex;
    const isOverHrLimit = reqBody.waveformIndexes.some((waveformIndex) =>
      this.isOverHrLimit({
        updateTargetWaveformIndex: waveformIndex,
        comparisonWaveformIndexList,
      })
    );

    if (isOverHrLimit) {
      result.push(EventEditValidation.OVER_HR_LIMIT);
    }

    // validation2: prevent add s beat in a-fib event
    if (reqBody.beatType === BeatType.APC) {
      const afList: TimeEvent[] = yield this.getAfList();
      const recordingStartMs: Ms = yield this.getRecordingStartMs();

      const isSBeatInAF = reqBody.waveformIndexes.some((waveformIndex) =>
        this.isSBeatInAF({
          afList,
          waveformIndex,
          recordingStartMs,
        })
      );

      if (isSBeatInAF) {
        result.push(EventEditValidation.S_BEAT_IN_AF);
      }
    }

    return result;
  }

  *_validation({
    reqBody,
    updateReqOption,
    mergedBeatsNEctopicList,
  }: Omit<Validation, 'copyMergedBeatsNEctopicList'>) {
    const {
      hr: hrList,
      beatType: beatTypeList,
      waveformIndex: waveformIndexList,
    } = mergedBeatsNEctopicList;

    const comparisonWaveformIndexList =
      mergedBeatsNEctopicList.waveformIndex.filter(
        (v) => !reqBody.waveformIndexes.includes(v)
      );
    const overHrLimitList = reqBody.waveformIndexes.filter((waveformIndex) =>
      this.isOverHrLimit({
        updateTargetWaveformIndex: waveformIndex,
        comparisonWaveformIndexList,
      })
    );

    if (overHrLimitList.length > 0) {
      for (let idx = 0; idx < reqBody.waveformIndexes.length; idx++) {
        const curWaveformIndex = reqBody.waveformIndexes[idx];
        const indexOfCurWaveformIndex =
          mergedBeatsNEctopicList.waveformIndex.indexOf(curWaveformIndex);
        if (indexOfCurWaveformIndex > -1) {
          hrList.splice(indexOfCurWaveformIndex, 1);
          beatTypeList.splice(indexOfCurWaveformIndex, 1);
          waveformIndexList.splice(indexOfCurWaveformIndex, 1);
        }
      }
    }

    if (reqBody.beatType === BeatType.APC) {
      const EVENT_CONST_TYPE_AF = EVENT_CONST_TYPES.AF;
      const { recordingStartMs } = yield select(selectRecordingTime);
      const afList: TimeEvent[] = yield select((state) =>
        selectTimeEventList(state, EVENT_CONST_TYPE_AF)
      );

      for (let idx = 0; idx < reqBody.waveformIndexes.length; idx++) {
        const updateTargetWaveform = reqBody.waveformIndexes[idx];
        const updateTargetWaveformIndexIndex = waveformIndexList.findIndex(
          (waveformIndex) => waveformIndex === updateTargetWaveform
        );
        const selectedEventTimeStamp =
          recordingStartMs + updateTargetWaveform * 4;
        let isSBeatInAf = false;
        for (let afInfo of afList) {
          if (
            selectedEventTimeStamp >= afInfo.onsetMs &&
            selectedEventTimeStamp <= afInfo.terminationMs
          ) {
            isSBeatInAf = true;
            break;
          }
        }
        if (isSBeatInAf) {
          hrList.splice(updateTargetWaveformIndexIndex, 1);
          beatTypeList.splice(updateTargetWaveformIndexIndex, 1);
          waveformIndexList.splice(updateTargetWaveformIndexIndex, 1);
        }
      }
    }

    return mergedBeatsNEctopicList;
  }

  getResult() {
    return this.result;
  }
}

// ### COMMAND 역할
export class PostBeatCommand {
  command: any;
  executeInst: any;

  constructor(value: any) {
    this.command = new Command(PostBeat, null, value);
  }

  *execute() {
    this.executeInst = new this.command.executeClass(this.command.value);
    yield this.executeInst.optimisticEventDataUpdate(this.command.value);
  }

  getResult() {
    return this.executeInst.getResult();
  }
}
